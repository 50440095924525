import React, { useContext } from 'react';
import 'modern-normalize';
import Helmet from 'react-helmet';
import clsx from 'clsx';
// import { graphql, useStaticQuery } from 'gatsby';

import { styled, ThemeWrapper } from 'styles';
import { info } from 'sitedata';
import Header from './Header';
import Sidebar from './Sidebar';
import MainLayoutContext, { ContextWrapper as MainLayoutContextWrapper } from './MainLayoutContext';

const MainLayout = ({ children, className }) => {
  const { sidebarOpen } = useContext(MainLayoutContext);

  return (
    <>
      <Helmet
        title={info.title}
        meta={[
          { name: 'description', content: info.description },
          { name: 'keywords', content: info.keywords },
          { name: 'bs:githead', content: process.env.GATSBY_GIT_HEAD },
          { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=0' }
        ]}
      />
      <ThemeWrapper>
        <StyledMainLayout className={clsx(className, { 'sidebar-open': sidebarOpen })}>
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="header">
            <Header />
          </div>
          <div className="content scrollbar-custom">{children}</div>
        </StyledMainLayout>
      </ThemeWrapper>
    </>
  );
};

export default props => (
  <MainLayoutContextWrapper>
    <MainLayout {...props} />
  </MainLayoutContextWrapper>
);

const StyledMainLayout = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-areas:
    'sidebar header'
    'sidebar content';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto 1fr auto;
  -ms-grid-columns: auto 1fr;
  height: 100%;
  min-height: 100vh;

  &.sidebar-open {
    .sidebar {
      width: 200px;
      @media (max-width: ${props => props.theme.breakpoints.sm}) {
        width: 100%;
        transform: translateX(0);
      }
    }
  }

  & > .sidebar {
    position: relative;
    z-index: 10;
    grid-area: sidebar;
    grid-row: 1 / span 2;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row-span: 2;
    overflow: hidden;
    transition: width 300ms, transform 300ms;
    height: 100%;
    width: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 14px 0px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      position: fixed;
      z-index: 1000;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
      transform: translateX(-100%);
    }
  }

  & > .header {
    grid-area: header;
    grid-row: 1;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    padding: 0 40px;
    height: 80px;
    z-index: 10;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 0 20px;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0 10px;
      height: 52px;
    }
  }

  & > .content {
    position: relative;
    z-index: 0;
    grid-area: content;
    grid-row: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    overflow: overlay;
    padding: 32px 40px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 32px 20px;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 16px 10px;
      overflow: auto;
    }
  }
`;
