import React, { useState } from 'react';
import dayjs from 'dayjs';
import notify from 'notify';
import { styled } from 'styles';
import { Button, Icon } from 'components';
import { tryGetFirstError } from 'utils/requests';
import { deleteNote as deleteNoteRequest } from '../api';

interface WorkspaceNoteProps {
  data: WorkspaceNote;
  onDelete: (noteId: number) => void;
}

const WorkspaceNote: React.FC<WorkspaceNoteProps> = ({ data, onDelete }) => {
  const [disableDelete, setDisableDelete] = useState(false);

  const deleteNote = async () => {
    setDisableDelete(true);
    try {
      await deleteNoteRequest(data.id);
      onDelete(data.id);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setDisableDelete(false);
  };

  return (
    <StyledWorkspaceNote>
      <Button className="delete-button" simple onClick={deleteNote} disabled={disableDelete}>
        <Icon name="trash" />
      </Button>
      <div className="note-text">{data.text}</div>
      <hr />
      <div className="note-info">
        {dayjs(data.createdOn).format('MM/DD/YYYY')} · {data.name}
      </div>
    </StyledWorkspaceNote>
  );
};

export default WorkspaceNote;

const StyledWorkspaceNote = styled.div`
  padding: 8px;
  border: 1px solid ${props => props.theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 8px;

  .delete-button {
    float: right;
    fill: ${props => props.theme.colors.grayDark};
  }

  .note-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-line;
    overflow: hidden;
  }

  .note-info {
    color: ${props => props.theme.colors.grayDark};
    font-size: 12px;
    line-height: 16px;
  }
`;
