import React, { useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { pdfjs, Document, Page } from 'react-pdf';
import { styled } from 'styles';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import useLayout from 'hooks/useLayout';
import { getDocumentTypeOptions } from 'utils/documents';
import { uploadDocument } from 'api/documents';
import { Button, Dropdown, Checkbox, Input, InputCurrency, InputDate, Icon } from 'components';
import documentTypes from 'utils/documentTypes.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const extraFields = {
  listingDocuments: [10, 16],
  contractAndAmendments: [1, 2, 3, 5, 6, 9],
  EMReceipt: [8]
};

interface UploadFileMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  listingId: string;
  onSuccess: (id: number) => void;
  onCancel?: () => void;
}

const UploadFileMenu: React.FC<UploadFileMenuProps> = ({ listingId, onSuccess, onCancel }) => {
  const layout = useLayout();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { register, control, errors, setValue, formState, watch, handleSubmit } = useForm({
    defaultValues: {
      file: '',
      doctype: '',
      draft: false
    }
  });
  const [file, setFile] = useState<File>();
  const [filePreviewSrc, setFilePreviewSrc] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { draft, doctype } = watch(['draft', 'doctype']);

  const handleFileInputChange = async e => {
    const file: File = e.target.files[0];
    if (!file) return;
    fileInputRef.current!.value = '';

    setValue('file', file.name);
    setFile(file);
    setCurrentPage(1);

    if (file?.type.includes('image') || file?.type.includes('pdf')) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setFilePreviewSrc(reader.result as string), false);
      reader.readAsDataURL(file);
    }
  };

  const submit = async values => {
    try {
      const docid = await uploadDocument({
        file: file!,
        doctype: values.doctype,
        listing: listingId,
        draft: values.draft,
        base_price: values.purchasePrice,
        earnest_money_1: values.earnestMoney1,
        earnest_money_2: values.earnestMoney2,
        contract_closing_date: values.contractClosingDate,
        closing_cost_credit: values.closingCostCredit,
        effective_date: values.executionDate,
        attorney_review_deadline: values.attorneyModificationDeadline,
        inspection_due_deadline: values.inspectionDeadline,
        mortgage_contingency_cleartoclose_deadline: values.mortgageContingencyDeadline,
        extras_total: values.extrasTotal,
        sales_commission: values.salesCommission,
        purchase_commission: values.purchaseCommission,
        em_role_specific: values.earnestMoneyHolder,
        earnest_money_1_deposit: values.earnestMoney1Deposit,
        earnest_money_2_deposit: values.earnestMoney2Deposit,
        earnest_money_3_deposit: values.earnestMoney3Deposit,
        earnest_money_4_deposit: values.earnestMoney4Deposit
      });
      onSuccess(docid);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  const preview = useMemo(() => {
    if (file?.type.includes('image')) {
      return <img className="preview-img" src={filePreviewSrc} alt="preview" />;
    }
    if (file?.type.includes('pdf')) {
      return (
        <>
          <div className="document-navigation">
            <Button
              simple
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(page => page - 1 || 1)}>
              <Icon name="arrow-simple" />
            </Button>
            {currentPage} / {totalPages}
            <Button
              simple
              disabled={currentPage === totalPages}
              onClick={() =>
                setCurrentPage(page => (page + 1 < totalPages ? page + 1 : totalPages))
              }>
              <Icon name="arrow-simple" />
            </Button>
          </div>
          <Document file={filePreviewSrc} onLoadSuccess={pdf => setTotalPages(pdf.numPages)}>
            <Page pageNumber={currentPage} width={458} />
          </Document>
        </>
      );
    }
    if (file)
      return (
        <p>
          Preview is not available for this
          <br />
          type of documents
        </p>
      );
    return <p>Select file to see preview</p>;
  }, [currentPage, file, filePreviewSrc, totalPages]);

  return (
    <StyledUploadFileMenu>
      <h2 className="title">Upload Document</h2>
      <div className="upload-document-columns">
        {layout !== 'mobile' && <div className="preview">{preview}</div>}
        <StyledForm onSubmit={handleSubmit(submit)}>
          <Input
            ref={register({ required: 'Required' })}
            name="file"
            label="Select file"
            icon="fc-file"
            placeholder="Upload"
            onClick={() => fileInputRef.current!.click()}
            error={errors.file?.message}
            readOnly
          />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileInputChange}
            style={{ display: 'none' }}
          />
          <hr />
          <Controller
            control={control}
            rules={{ required: 'Required' }}
            name="doctype"
            defaultValue=""
            render={controllerProps => (
              <Dropdown
                options={getDocumentTypeOptions(documentTypes)}
                error={errors.doctype?.message}
                label="Type"
                placeholder="Select"
                search
                {...controllerProps}
              />
            )}
          />
          <Checkbox ref={register} name="draft" label="Draft" />
          {draft === false && (
            <>
              {extraFields.EMReceipt.includes(doctype) && (
                <>
                  <Input
                    ref={register({ required: 'Required' })}
                    name="earnestMoneyHolder"
                    error={errors.EMReceipt?.message}
                    label="Earnest Money Holder"
                    placeholder="Enter"
                  />
                  <Controller
                    control={control}
                    name="earnestMoney1Deposit"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Earnest Money Deposit (1st inst)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="earnestMoney2Deposit"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Earnest Money Deposit (2st inst)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="earnestMoney3Deposit"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Earnest Money Deposit (3st inst)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="earnestMoney4Deposit"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Earnest Money Deposit (4st inst)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                </>
              )}
              {extraFields.listingDocuments.includes(doctype) && (
                <>
                  <Controller
                    control={control}
                    name="salesCommission"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Seller Commissions ($)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="purchase_commission"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Buyer Commissions ($)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                </>
              )}
              {extraFields.listingDocuments.includes(doctype) && (
                <>
                  <Controller
                    control={control}
                    name="salesCommission"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Seller Commissions ($)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="purchase_commission"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Buyer Commissions ($)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                </>
              )}
              {extraFields.contractAndAmendments.includes(doctype) && (
                <>
                  <Controller
                    control={control}
                    name="purchasePrice"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Purchase Price"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="earnestMoney1"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Earnest Money (1st inst)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="earnestMoney2"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Earnest Money (2st inst)"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="closingDate"
                    defaultValue=""
                    render={controllerProps => (
                      <InputDate
                        inputProps={{ label: 'Closing Date', placeholder: 'MM/YY/DD' }}
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="closingCostCredit"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Closing Cost Credit"
                        placeholder="Enter"
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="executionDate"
                    defaultValue=""
                    render={controllerProps => (
                      <InputDate
                        inputProps={{ label: 'Execution Date', placeholder: 'MM/YY/DD' }}
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="attorneyModificationDeadline"
                    defaultValue=""
                    render={controllerProps => (
                      <InputDate
                        inputProps={{
                          label: 'Attorney Modification Deadline',
                          placeholder: 'MM/YY/DD'
                        }}
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="inspectionDeadline"
                    defaultValue=""
                    render={controllerProps => (
                      <InputDate
                        inputProps={{ label: 'Inspection Deadline', placeholder: 'MM/YY/DD' }}
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="mortgageContingencyDeadline"
                    defaultValue=""
                    render={controllerProps => (
                      <InputDate
                        inputProps={{
                          label: 'Mortgage Contingency Deadline',
                          placeholder: 'MM/YY/DD'
                        }}
                        {...controllerProps}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="extrasTotal"
                    defaultValue=""
                    render={controllerProps => (
                      <InputCurrency
                        label="Extras (Total)"
                        placeholder="0.00"
                        {...controllerProps}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}
          {layout === 'mobile' && (
            <>
              <Button secondary onClick={onCancel}>
                Cancel
              </Button>
              <Button disabled={formState.isSubmitting}>Upload</Button>
            </>
          )}
        </StyledForm>
      </div>
      {layout !== 'mobile' && (
        <div className="form-buttons">
          <Button secondary onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={formState.isSubmitting} onClick={handleSubmit(submit)}>
            Upload
          </Button>
        </div>
      )}
    </StyledUploadFileMenu>
  );
};

export default UploadFileMenu;

const StyledUploadFileMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }

  .upload-document-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    height: 100%;
    overflow: auto;
    margin-bottom: 50px;
    grid-gap: 32px;

    & > * {
      width: 100%;
    }
  }

  .preview {
    border-right: 1px solid ${props => props.theme.colors.borderColor};
    font-size: 16px;
    line-height: 24px;
    color: #8b8b8b;
    text-align: center;

    .document-navigation {
      .button {
        margin: 0 10px;
        padding: 3px;

        &:nth-of-type(2) .icon {
          transform: rotate(180deg);
        }
      }
    }

    .react-pdf__Document {
      width: 100%;
    }

    img {
      width: 100%;
    }

    p {
      margin-top: 20%;
    }
  }

  form {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      padding-left: 32px;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    width: calc(100% + 40px);
    margin: 0 -20px -24px -20px !important;
    padding: 8px 27px;
    background: #f8f8f8;

    .button:nth-of-type(1) {
      margin-right: 16px;
    }
  }
`;

const StyledForm = styled.form`
  .input,
  .dropdown {
    width: 100%;
  }

  .input,
  .dropdown,
  .checkbox {
    margin: 8px 0;
  }

  hr {
    margin-bottom: 16px;
  }

  .button {
    width: 100%;
    margin-top: 8px;
  }
  button {
    &.prev,
    &.next {
      width: auto;
    }
  }
`;
