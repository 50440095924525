import React, { useContext } from 'react';
import pluralize from 'pluralize';

import { styled } from 'styles';
import WorkspaceContext from '../WorkspaceContext';

const WorkspaceInfoTab = () => {
  const { listing } = useContext(WorkspaceContext);
  const { propertyDetails, listingAgent } = listing! || {};

  const numberFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 });

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  const getAddress = (listing: Listing) => {
    const { city, state, postalCode } = listing;
    return `${[city, state].filter(Boolean).join(', ')} ${postalCode || ''}`;
  };

  if (!listing) return null;

  const isSchoolsEpmty = listing.schools && Object.values(listing.schools).every(item => !item);

  return (
    <StyledListing className="listing">
      <div className="listing-top-section">
        <div className="listing-title">
          <h1 className="listing-title__address1" data-cy="address1">
            {listing.address1}
            {listing.address2 && <>, {listing.address2}</>}
          </h1>
          <h2 className="listing-title__address2" data-cy="address2">
            {getAddress(listing)}
          </h2>
        </div>

        <div className="listing-info">
          <div className="listing-info__block">
            <div className="listing-info__value" data-cy="price">
              {currencyFormatter.format(listing.currentPrice)}
            </div>
            <div className="listing-info__name">Price</div>
          </div>

          <div className="listing-info__block">
            <div className="listing-info__value" data-cy="baths">
              {propertyDetails.baths}
            </div>
            <div className="listing-info__name">Baths</div>
          </div>

          <div className="listing-info__block">
            <div className="listing-info__value" data-cy="beds">
              {propertyDetails.beds}
            </div>
            <div className="listing-info__name">Beds</div>
          </div>

          <div className="listing-info__block">
            <div className="listing-info__value" data-cy="sqft">
              {numberFormatter.format(propertyDetails.sqft)}
            </div>
            <div className="listing-info__name">sqft</div>
          </div>
        </div>
      </div>
      <div className="listing__container">
        <main className="listing__main">
          {listingAgent?.name && (
            <section className="listing-details agent" id="agent">
              <h3 className="listing-details__title">Listing Agent</h3>
              <div className="agent__info">
                <div className="agent__title">
                  <div className="listing-details__info agent-name">{listingAgent.name}</div>
                  <div className="listing-details__info listing-details__agency">
                    {listingAgent.agency}
                  </div>
                </div>
                <div className="agent__contact">
                  {listingAgent.officePhone && (
                    <dl className="listing-details__info">
                      <dt className="listing-details__name">Office Phone:&nbsp;</dt>
                      <dd className="listing-details__value" data-cy="agent_phone">
                        <a
                          href={`tel:${listingAgent.officePhone}`}
                          className="listing-details__link">
                          {listingAgent.officePhone}
                        </a>
                      </dd>
                    </dl>
                  )}
                  {listingAgent?.mobilePhone && (
                    <dl className="listing-details__info">
                      <dt className="listing-details__name">Mobile Phone:&nbsp;</dt>
                      <dd className="listing-details__value" data-cy="agent_phone">
                        <a
                          href={`tel:${listingAgent.mobilePhone}`}
                          className="listing-details__link">
                          {listingAgent.mobilePhone}
                        </a>
                      </dd>
                    </dl>
                  )}
                  {listingAgent?.email && (
                    <dl className="listing-details__info">
                      <dt className="listing-details__name">Email:&nbsp;</dt>
                      <dd className="listing-details__value" data-cy="agent_email">
                        {listingAgent.email.map(email => (
                          <a
                            href={`mailto:${email}`}
                            className="listing-details__link email"
                            key={email}>
                            {email}
                          </a>
                        ))}
                      </dd>
                    </dl>
                  )}
                </div>
              </div>
            </section>
          )}
          <section className="listing-details features" id="features">
            <h3 className="listing-details__title">Features</h3>
            <div className="listing-details__list">
              <div className="listing-details__list-container">
                <dl className="listing-details__info">
                  <dt className="listing-details__name">Status</dt>
                  <dd className="listing-details__value" data-cy="status">
                    {listing.status}
                  </dd>
                </dl>

                <dl className="listing-details__info">
                  <dt className="listing-details__name">Property Type</dt>
                  <dd className="listing-details__value" data-cy="property-type">
                    {listing.propertyType}
                  </dd>
                </dl>

                <dl className="listing-details__info">
                  <dt className="listing-details__name">Community</dt>
                  <dd className="listing-details__value" data-cy="neighborhood">
                    {listing.neighborhood}
                  </dd>
                </dl>

                <dl className="listing-details__info">
                  <dt className="listing-details__name">MLS#</dt>
                  <dd className="listing-details__value" data-cy="mls-id">
                    {listing.listingMlsId}
                  </dd>
                </dl>
              </div>
              <div className="listing-details__list-container">
                <dl className="listing-details__info">
                  <dt className="listing-details__name">Time on Website</dt>
                  <dd className="listing-details__value" data-cy="days-on-market">{listing.daysOnMarket ? `${listing.daysOnMarket
                    } ${pluralize('day', listing.daysOnMarket)}` : 'N/A'}</dd>
                </dl>

                {Boolean(propertyDetails.yearBuilt) && propertyDetails.yearBuilt! > 0 && (
                  <dl className="listing-details__info">
                    <dt className="listing-details__name">Year Built</dt>
                    <dd className="listing-details__value" data-cy="year-built">
                      {propertyDetails.yearBuilt}
                    </dd>
                  </dl>
                )}

                {propertyDetails.lotSize && (
                  <dl className="listing-details__info">
                    <dt className="listing-details__name">Lot Size</dt>
                    <dd className="listing-details__value" data-cy="lot-size">
                      {propertyDetails.lotSize}
                    </dd>
                  </dl>
                )}
              </div>
            </div>
          </section>
          {!isSchoolsEpmty && (
            <section className="listing-details schools" id="schools">
              <h3 className="listing-details__title">Schools</h3>
              {Boolean(listing.schools) &&
                Object.keys(listing.schools!).map(key => (
                  <dl className="listing-details__info" key={key}>
                    <dt className="listing-details__name" data-cy={key}>
                      {listing.schools![key]}
                    </dt>
                  </dl>
                ))}
            </section>
          )}
          {listing.currentPrice && (
            <section className="listing-details affordability" id="affordability">
              <h3 className="listing-details__title">Affordability</h3>
            </section>
          )}
          <section className="listing-details property-details" id="property-details">
            <h3 className="listing-details__title">Property Details for Address</h3>
            <div className="listing-details__box listview">
              <div className="listview__header">Interior Features</div>
              <figure className="listview__list__group">
                <figcaption className="listview__list__caption">Bedroom Information</figcaption>
                <ul className="listview__list">
                  <li className="listview__list__item" data-cy="beds-info">
                    # of Bedrooms: {propertyDetails.beds}
                  </li>
                </ul>
              </figure>

              <figure className="listview__list__group">
                <figcaption className="listview__list__caption">Bathroom Information</figcaption>
                <ul className="listview__list">
                  <li className="listview__list__item" data-cy="baths-info">
                    # of Baths: {propertyDetails.baths}
                  </li>
                  <li className="listview__list__item" data-cy="baths-full">
                    # of Baths (Full): {propertyDetails.bathsFull}
                  </li>
                  {Boolean(propertyDetails.bathsHalf) && (
                    <li className="listview__list__item" data-cy="baths-half">
                      # of Baths (Half): {propertyDetails.bathsHalf}
                    </li>
                  )}
                </ul>
              </figure>

              <figure className="listview__list__group">
                <figcaption className="listview__list__caption">Heating & Cooling</figcaption>
                <ul className="listview__list">
                  <li className="listview__list__item" data-cy="heat">
                    Heating: {propertyDetails.heat || 'N/A'}
                  </li>
                  <li className="listview__list__item" data-cy="cool">
                    Cooling: {propertyDetails.cool || 'N/A'}
                  </li>
                </ul>
              </figure>

              {propertyDetails.petsAllowed && (
                <figure className="listview__list__group">
                  <figcaption className="listview__list__caption">Pets Allowed</figcaption>
                  <ul className="listview__list">
                    <li className="listview__list__item" data-cy="pets-allowed">
                      {propertyDetails.petsAllowed}
                    </li>
                  </ul>
                </figure>
              )}

              {propertyDetails.equipment && (
                <figure className="listview__list__group">
                  <figcaption className="listview__list__caption">Equipment</figcaption>
                  <ul className="listview__list">
                    <li className="listview__list__item" data-cy="equipment">
                      {propertyDetails.equipment}
                    </li>
                  </ul>
                </figure>
              )}

              {propertyDetails.appliances && (
                <figure className="listview__list__group">
                  <figcaption className="listview__list__caption">Appliances</figcaption>
                  <ul className="listview__list">
                    <li className="listview__list__item" data-cy="appliances">
                      {propertyDetails.appliances}
                    </li>
                  </ul>
                </figure>
              )}
            </div>

            <div className="listing-details__box listview">
              <div className="listview__header">
                Parking / Garage, Exterior Features, School / Neighborhood, HOA Includes, Utilities
              </div>
              {(propertyDetails.parkingFeatures ||
                Boolean(propertyDetails.parkingSpaces) ||
                Boolean(propertyDetails.garageSpaces)) && (
                  <figure className="listview__list__group">
                    <figcaption className="listview__list__caption">Parking Information</figcaption>
                    <ul className="listview__list">
                      {propertyDetails.parkingFeatures && (
                        <li className="listview__list__item" data-cy="parking-features">
                          {propertyDetails.parkingFeatures}
                        </li>
                      )}
                      {propertyDetails.parkingSpaces && (
                        <li className="listview__list__item" data-cy="parking-spaces">
                          # of Exterior Parking Spaces: {propertyDetails.parkingSpaces}
                        </li>
                      )}
                      {propertyDetails.garageType && (
                        <li className="listview__list__item" data-cy="garage-type">
                          Garage Type: {propertyDetails.garageType}
                        </li>
                      )}
                      {propertyDetails.garageSpaces && (
                        <li className="listview__list__item" data-cy="garage-spaces">
                          # of Garage Spaces: {propertyDetails.garageSpaces}
                        </li>
                      )}
                    </ul>
                  </figure>
                )}
              {propertyDetails.exteriorFeatures && (
                <figure className="listview__list__group">
                  <figcaption className="listview__list__caption">Exterior Features</figcaption>
                  <ul className="listview__list">
                    <li className="listview__list__item" data-cy="exterior-features">
                      {propertyDetails.exteriorFeatures}
                    </li>
                  </ul>
                </figure>
              )}

              {propertyDetails.hoaIncludes && (
                <figure className="listview__list__group">
                  <figcaption className="listview__list__caption">HOA Includes</figcaption>
                  <ul className="listview__list">
                    <li className="listview__list__item" data-cy="hoa-includes">
                      {propertyDetails.hoaIncludes}
                    </li>
                  </ul>
                </figure>
              )}
              {propertyDetails.utilities && (
                <figure className="listview__list__group">
                  <figcaption className="listview__list__caption">Utilities</figcaption>
                  <ul className="listview__list">
                    {propertyDetails.utilities.sewer && (
                      <li className="listview__list__item" data-cy="utilities-sewer">
                        Sewer: {propertyDetails.utilities.sewer}
                      </li>
                    )}
                    {propertyDetails.utilities.water && (
                      <li className="listview__list__item" data-cy="utilities-water">
                        Water: {propertyDetails.utilities.water}
                      </li>
                    )}
                  </ul>
                </figure>
              )}
            </div>
          </section>
        </main>
      </div>
    </StyledListing>
  );
};

export default React.memo(WorkspaceInfoTab);

const borderRadius = props => props.theme.misc.borderRadius;
const borderColor = props => props.theme.colors.borderColor;
const small = props => props.theme.breakpoints.sm;
const medium = props => props.theme.breakpoints.md;

const StyledListing = styled.div`
  margin: 24px auto;
  width: 100%;
  box-sizing: border-box;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: normal;
  }
  .listing-top-section {
    display: flex;
    padding: 0 0 12px;
    justify-content: space-between;
  }

  .listing-title {
    h1.listing-title__address1 {
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 24px;
    }

    h2.listing-title__address2 {
      font-size: 12px;
      line-height: 20px;
      color: ${props => props.theme.colors.dustyGray};
    }
  }

  .listing-info {
    display: flex;

    .listing-info__block {
      padding: 0 16px;
      border-right: 1px solid #000000;
      text-align: center;
      &:last-of-type {
        border-right: none;
      }
    }

    .listing-info__value {
      font-size: 14px;
      line-height: 24px;
    }

    .listing-info__name {
      font-size: 12px;
      line-height: 20px;
      color: ${props => props.theme.colors.dustyGray};
    }
  }

  .listing__container {
    display: flex;
  }

  .listing__main {
    flex: 1;
  }

  .listing__aside {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 401px;
    padding-left: 46px;
  }

  .listing-details {
    padding: 10px 0;
    font-size: 12px;
    line-height: 22px;

    .listing-details__title {
      margin-bottom: 16px;
      padding-bottom: 4px;
      font-size: 12px;
      font-weight: 500;
      border-bottom: 1px solid ${borderColor};
    }

    .listing-details__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &__item {
        margin: 0 0 12px;
        font-size: 16px;
        line-height: 24px;
        color: #545454;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .listing-details__info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 0 12px 0;
      font-size: 12px;
      line-height: 16px;
      &.agent-name {
        font-weight: 500;
      }

      .listing-details__name {
        margin-right: 16px;
        grid-column-start: 1;
        color: #545454;
        min-width: 81px;
      }

      .listing-details__value {
        grid-column-start: 2;
        margin: 0;
        font-weight: 500;
      }
    }

    .listing-details__box {
      border: 1px solid ${borderColor};
      border-radius: ${borderRadius};

      &:nth-of-type(2) {
        border-top: none;
        border-radius: 0 0 ${borderRadius} ${borderRadius};
      }
    }

    .listing-details__link {
      display: block;
      color: #000;
      text-decoration: none;
      &.email {
        margin: 0 0 4px;
      }
    }

    .listview {
      .listview__header {
        padding: 12px;
        font-weight: 500;
        border-bottom: 1px solid ${borderColor};
      }

      .listview__list__group {
        margin: 6px 0;
        padding: 0 12px;
      }

      .listview__list__caption {
        margin-bottom: 4px;
      }

      .listview__list {
        margin: 0;
        padding-left: 26px;

        .listview__list__item {
          margin-bottom: 4px;
          color: #545454;
          word-break: break-word;
        }
      }
    }

    &.agent {
      .agent__info {
        display: flex;
      }
      .agent__title {
        flex: 1;
      }
      .agent__contact {
        flex: 3;
      }
      .listing-details__agency {
        color: #545454;
      }
      .listing-details__info {
        display: flex;
      }
    }

    &.property-details {
      .listing-details__title {
        border: none;
      }
    }

    @media (max-width: ${medium}) {
      .listing-top-section {
        flex-wrap: wrap;
        margin-top: 6px;
        margin-bottom: 8px;
      }
      .listing-details__name {
        margin-right: 50px;
      }

      .listing-details__list {
        .listing-details__list__item {
          font-size: 12px;
          line-height: 22px;
        }
      }

      .listview {
        .listview__header {
          margin-bottom: 6px;
          font-size: 12px;
          line-height: 22px;
        }

        .listview__list__group {
          &:first-of-type {
            padding-top: 6px;
          }
          &:last-of-type {
            padding-bottom: 6px;
          }
        }

        .listview__list__caption,
        .listview__list > .listview__list__item {
          font-size: 12px;
          line-height: 20px;
        }
      }

      &.property-details {
        .listing-details__title {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: ${medium}) {
    .listing-top-section {
      flex-flow: column;
    }

    .listing-info {
      margin: 8px 0 16px;
      justify-content: space-between;

      .listing-info__block {
        text-align: left;
        border-right: none;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    .listing__container {
      padding-top: 0;
      flex-flow: column;
    }

    .listing-details {
      &__title {
        margin-bottom: 12px;
      }
    }

    .floating-listing-contact {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  @media (max-width: ${small}) {
    .listing-details {
      &.agent {
        .agent__info {
          flex-flow: column;
        }
        .listing-details__name {
          margin-right: 0;
        }
        .listing-details__info {
          display: grid;
        }
      }

      &__info {
        font-size: 12px;
        grid-template-columns: 3fr 1fr;
      }

      .listing-details__list {
        grid-template-columns: 1fr;
      }
    }
  }
`;
