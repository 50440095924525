import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import useOpenContractBuilder from 'hooks/useOpenContractBuilder';
import {
  deleteDocument as deleteDocumentRequest,
  deleteMultipleDocuments,
  downloadDocument,
  downloadDocumentsArchive,
  getDocuments,
  shareDocuments
} from 'api/documents';
import WorkspaceContext from '../WorkspaceContext';

export default () => {
  const { listing } = useContext(WorkspaceContext);
  const [documents, setDocuments] = useState<WorkspaceDocument[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [selectedDocumentCategories, setSelectedDocumentCategories] = useState<string[]>([]);
  const [uploadMenuOpen, setUploadMenuOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [disableContractBuilderButton, openContractBuilder] = useOpenContractBuilder();
  const [deleteDocumentData, setDeleteDocumentData] = useState<number[]>();
  const [shareDocumentData, setShareDocumentData] = useState<number[]>();
  const [disableActions, setDisableActions] = useState(false);

  const { contractAndAmendments, listingDocuments, finalClosingDocuments } = useMemo(() => {
    const contractAndAmendments: WorkspaceDocument[] = [];
    const listingDocuments: WorkspaceDocument[] = [];
    const finalClosingDocuments: WorkspaceDocument[] = [];

    documents.forEach(document => {
      switch (document.category) {
        case 'contract_amendments':
          contractAndAmendments.push(document);
          break;
        case 'listing_documents':
          listingDocuments.push(document);
          break;
        case 'final_closing_documents':
          finalClosingDocuments.push(document);
          break;
        default:
          break;
      }
    });
    return { contractAndAmendments, listingDocuments, finalClosingDocuments };
  }, [documents]);

  const fetchDocuments = useCallback(async listingId => {
    try {
      setDocuments(await getDocuments(listingId));
    } catch (err) {
      notify(err.response || err.message);
    }
  }, []);

  useEffect(() => {
    if (!listing) return;
    fetchDocuments(listing.id);
  }, [fetchDocuments, listing]);

  const handleFileUploadSuccess = () => {
    setUploadMenuOpen(false);
    fetchDocuments(listing!.id);
  };

  const handleDocumentSelectionChange = (e, id) => {
    const { checked } = e.target;
    const documentsToSelect = checked
      ? [...selectedDocuments, id]
      : selectedDocuments.filter(item => item !== id);
    const selectedDocument = documents.find(document => document.id === id);
    const documentsInCategory = documents
      .filter(document => document.category === selectedDocument?.category)
      .map(document => document.id);

    setSelectedDocuments(documentsToSelect);

    if (!checked) {
      return setSelectedDocumentCategories(items =>
        items.filter(item => item !== selectedDocument?.category)
      );
    }

    if (documentsInCategory.every(item => documentsToSelect.includes(item))) {
      setSelectedDocumentCategories(items => [...items, String(selectedDocument?.category)]);
    }
  };

  const handleDocumentsGroupSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const checkedDocuments = documents.filter(document => selectedDocuments.includes(document.id));

    if (checked) {
      setSelectedDocumentCategories(items => [...items, value]);
      return setSelectedDocuments(items => [
        ...items,
        ...documents.filter(document => document.category === value).map(document => document.id)
      ]);
    }

    setSelectedDocumentCategories(items => items.filter(item => item !== value));
    setSelectedDocuments(
      checkedDocuments.filter(document => document.category !== value).map(document => document.id)
    );
  };

  const deleteSelectedDocuments = async () => {
    try {
      await deleteMultipleDocuments(selectedDocuments);
      setDocuments(items => items.filter(item => !selectedDocuments.includes(item.id)));
      setSelectedDocuments([]);
      setDeleteDocumentData(undefined);
    } catch (err) {
      notify(err.response || err.message);
    }
  };

  const deleteDocument = async () => {
    if (!deleteDocumentData) return;
    setDisableActions(true);
    try {
      const id = deleteDocumentData[0];
      setDeleteDocumentData(undefined);
      await deleteDocumentRequest(String(id));
      setDocuments(documents => documents.filter(item => item.id !== id));
      notify('Document deleted');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setDisableActions(false);
  };

  const downloadSelectedDocuments = async () => {
    setIsDownloading(true);
    setDisableActions(true);
    try {
      const a = document.createElement('a');
      if (selectedDocuments.length > 1) {
        const archive = await downloadDocumentsArchive(selectedDocuments);
        a.href = archive;
        a.download = 'documents.zip';
      } else {
        const document = await downloadDocument(selectedDocuments[0]);
        a.href = document.url;
        a.download = document.name;
      }
      a.target = '_blank';
      a.rel = 'noreferrer';
      a.click();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setIsDownloading(false);
    setDisableActions(false);
  };

  const shareSelectedDocuments = async (memberIds: number[]) => {
    setDisableActions(true);
    try {
      if (!shareDocumentData?.length) throw Error('No document ids');
      await shareDocuments({
        ids: shareDocumentData,
        listingMembers: memberIds
      });
      notify('Document shared');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setShareDocumentData(undefined);
    setDisableActions(false);
  };

  useEffect(() => {
    if (documents.length === 0) setSelectedDocuments([]);
  }, [documents.length]);

  return {
    contractAndAmendments,
    deleteDocument,
    deleteDocumentData,
    deleteSelectedDocuments,
    disableActions,
    disableContractBuilderButton,
    documents,
    downloadSelectedDocuments,
    finalClosingDocuments,
    handleDocumentSelectionChange,
    handleDocumentsGroupSelectionChange,
    handleFileUploadSuccess,
    isDownloading,
    listing,
    listingDocuments,
    openContractBuilder,
    selectedDocuments,
    selectedDocumentCategories,
    setDeleteDocumentData,
    setShareDocumentData,
    setUploadMenuOpen,
    shareDocumentData,
    shareSelectedDocuments,
    uploadMenuOpen,
    setSelectedDocuments
  };
};
