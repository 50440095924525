import React, { useContext, useMemo } from 'react';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import { Tabs, SideMenu, Image, Icon } from 'components';
import WorkspaceContext, { WorkspaceContextWrapper } from './WorkspaceContext';
import WorkspaceDocumentsTab from './WorkspaceDocumentsTab';
import WorkspaceOffersTab from './WorkspaceOffersTab';
import WorkspaceInfoTab from './WorkspaceInfoTab';
import WorkspaceMembersTab from './WorkspaceMembersTab';
import WorkspaceTasksTab from './WorkspaceTasksTab';
import WorkspaceActivityTab from './WorkspaceActivityTab';
import { getAddressAndCity } from 'utils/listing';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const tabs = [
  { key: 'documents', text: 'Documents' },
  { key: 'information', text: 'Listing Information' },
  { key: 'offers', text: 'Offers' },
  { key: 'members', text: 'Members' },
  { key: 'tasks', text: 'Tasks' },
  { key: 'activity', text: 'Activity' }
];

const EmailRow = ({ email, ...props }) => (
  <div {...props}>
    <Icon name="email" />
    <a href={`mailto:${email}`} className="link">
      {email}
    </a>
  </div>
);

const PhoneRow = ({ phone, icon, ...props }) => (
  <div {...props}>
    <Icon name={icon} />{' '}
    <a href={`tel:${phone}`} className="link">
      {phone}
    </a>
  </div>
);

const Workspace = React.memo(() => {
  const { listing, workspaceOpen, closeWorkspace, workspaceTab, setWorkspaceTab } = useContext(
    WorkspaceContext
  );
  const layout = useLayout();

  const tabContent = useMemo(() => {
    switch (workspaceTab) {
      case 'documents':
        return <WorkspaceDocumentsTab />;
      case 'information':
        return <WorkspaceInfoTab />;
      case 'offers':
        return <WorkspaceOffersTab />;
      case 'members':
        return <WorkspaceMembersTab />;
      case 'tasks':
        return <WorkspaceTasksTab />;
      case 'activity':
        return <WorkspaceActivityTab />;
      default:
        return null;
    }
  }, [workspaceTab]);

  if (!listing) return null;

  const isBroker = !listing.sellerEmail;

  const getEmail = () => {
    if (listing.sellerEmail) {
      return <EmailRow email={listing.sellerEmail} className="top-section__info-block-row email" />;
    }
    return listing.listingAgent.email?.map(email => <EmailRow email={email} key={email} className="top-section__info-block-row email" />);
  };

  return (
    <StyledWorkspace className="workspace" open={workspaceOpen} onClose={closeWorkspace}>
      <div className="top-section">
        <div className="top-section__property">
          <Image cloudFront={listing.images[0]} size="248x248" className="top-section__photo" />
          <div className="top-section__info-block">
            <div className="top-section__info-block-row address">
              {getAddressAndCity(listing)}
            </div>
            <div className="top-section__info-block-row price">
              {currencyFormatter.format(listing.currentPrice)}
            </div>
          </div>
        </div>
        <div className="top-section__info-block">
          {layout === 'mobile' && (
            <span className="top-section__info-label">Broker / Seller Info</span>
          )}
          <div className="top-section__info-block-row name">
            <Icon name="user" />
            {listing.sellerName || listing.listingAgent.name}{' '}
            {isBroker && `(Broker)`}
          </div>
          {getEmail()}
          {listing.sellerPhone && (
            <PhoneRow phone={listing.sellerPhone} icon="phone" className="top-section__info-block-row phone" />
          )}
          {!listing.sellerPhone && listing.listingAgent.officePhone && (
            <PhoneRow phone={listing.listingAgent.officePhone} icon="phone" className="top-section__info-block-row phone" />
          )}
          {!listing.sellerPhone && listing.listingAgent.mobilePhone && (
            <PhoneRow phone={listing.listingAgent.mobilePhone} icon="smartphone" className="top-section__info-block-row phone" />
          )}
        </div>
      </div>
      <StyledTabs
        activeTab={workspaceTab!}
        onSelect={(_e, value) => setWorkspaceTab(value.key)}
        items={tabs}
      />
      {tabContent}
    </StyledWorkspace>
  );
});

export default ({ children }) => (
  <WorkspaceContextWrapper>
    <Workspace />
    {children}
  </WorkspaceContextWrapper>
);

const StyledWorkspace = styled(SideMenu)`
  & > .side-menu {
    display: flex;
    flex-direction: column;
    width: 1024px;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px;

    &__property {
      display: flex;
      flex: 1;
    }

    &__info-block {
      margin-right: 66px;
      font-size: 14px;
      line-height: 16px;
    }
    &__item-img {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
      flex-shrink: 0;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__info-block-row {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      &.address {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      &.price {
        color: ${props => props.theme.colors.grayDark};
      }
      &.email,
      &.phone,
      &.phone {
        color: ${props => props.theme.colors.mineShaft};
        .icon {
          margin: 0 12px 0 0;
          flex-shrink: 0;
        }
      }
      &.name {
        font-weight: 500;
        .icon {
          margin: 0 12px 0 0;
        }
      }
      .link {
        text-decoration: none;
        color: unset;
      }
    }

    &__photo {
      width: 124px;
      height: 124px;
      object-fit: cover;
      margin-right: 20px;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__info-label {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.darkGray};
      margin: 0 0 8px;
      display: block;
    }
  }

  .not-found-message {
    font-size: 16px;
    line-height: 24px;
    color: #8b8b8b;
    text-align: center;
    margin: 64px 0 0;
    &.notes {
      margin: 8px 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .tab-item {
      padding: 6px;
      margin-right: 6px;
      flex-shrink: 0;
    }
    .top-section {
      flex-direction: column;
      justify-content: flex-start;
      &__info-block {
        margin-right: 0;
      }
      &__photo {
        width: 64px;
        height: 64px;
      }
      &__property {
        margin: 0 32px 24px 0;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  flex-shrink: 0;
  flex-wrap: nowrap;
  .tab-item {
    width: 100%;
    font-weight: 600;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    overflow-x: auto;
    .tab-item {
      width: auto;
    }
  }
`;
