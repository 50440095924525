import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { styled } from 'styles';
import notify from 'notify';
import { capitalize } from 'lodash';
import { CalendarItem, CalendarItemType } from 'views/CalendarPage/calendar';
import { createEvent, createTask, editTask, editEvent } from 'views/CalendarPage/api';
import Modal, { ModalProps } from 'components/Modal';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Icon from 'components/Icon';
import Button from 'components/Button';
import EditCustomEventForm from './EditCustomEventForm';
import EditTaskForm from './EditTaskForm';
import { tryGetFirstError } from 'utils/requests';

type ProjectTypeOptions = { value: CalendarItemType; text: string }[];

interface EditEventProps extends ModalProps {
  propertyId: string;
  data?: Partial<CalendarItem>;
  dateType?: CalendarItemType;
  documentsOptions: DropdownOption[];
  usersOptions: DropdownOption[];
  onDeleteClick: (value: CalendarItem) => void;
  onEditSuccess: () => void;
}

const WorkspaceEditEventModal: React.FC<EditEventProps> = ({
  propertyId,
  data,
  dateType: dateTypeProp,
  documentsOptions,
  usersOptions,
  onDeleteClick,
  onEditSuccess,
  ...props
}) => {
  const [dateType, setDateType] = useState('task');

  const addEventCallback = useCallback(
    async values => {
      try {
        let createRequest;
        if (values.dateType === 'task') createRequest = createTask;
        else createRequest = createEvent;
        await createRequest({ ...values, propertyId });
        onEditSuccess();
        notify(`${capitalize(values.dateType)} successfully created`);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    },
    [onEditSuccess, propertyId]
  );

  const editEventCallback = useCallback(
    async values => {
      try {
        let updateRequest;
        if (values.dateType === 'task') updateRequest = editTask;
        else updateRequest = editEvent;

        await updateRequest({ ...values, propertyId });
        onEditSuccess();
        notify(`${capitalize(values.dateType)} successfully edited`);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    },
    [onEditSuccess, propertyId]
  );

  useEffect(() => {
    if (dateTypeProp) setDateType(dateTypeProp);
  }, [dateTypeProp]);

  const form = useMemo(() => {
    switch (dateType) {
      case 'event':
        return (
          <EditCustomEventForm
            initialData={data}
            usersOptions={usersOptions}
            onSubmitEdit={editEventCallback}
            onSubmitNew={addEventCallback}
          />
        );
      case 'task':
        return (
          <EditTaskForm
            initialData={data}
            usersOptions={usersOptions}
            documentsOptions={documentsOptions}
            onSubmitEdit={editEventCallback}
            onSubmitNew={addEventCallback}
          />
        );
      default:
        break;
    }
  }, [addEventCallback, data, dateType, documentsOptions, editEventCallback, usersOptions]);

  const projectTypeOptions: ProjectTypeOptions = [
    { value: 'event', text: 'Event' },
    { value: 'task', text: 'Task' }
  ];

  const isEditing = data?.id;

  return (
    <StyledModal
      modalTitle={isEditing ? `Edit ${data?.dateType}` : `Add new`}
      data-cy="modal_edit_add_new"
      {...props}>
      {isEditing && (
        <Button
          simple
          className="delete-btn"
          onClick={() => onDeleteClick(data as CalendarItem)}
          data-cy="button_delete_calendar_event">
          <Icon name="delete" size={12} />
        </Button>
      )}
      {!isEditing && (
        <Dropdown
          options={projectTypeOptions}
          label="Choose type"
          value={dateType}
          onChange={setDateType}
          data-cy="dropdown_date_type"
        />
      )}
      {form}
    </StyledModal>
  );
};

export default WorkspaceEditEventModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;

    .delete-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 12px;
      right: 44px;
      fill: ${props => props.theme.colors.grayDark};
    }
  }
`;
