import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import { styled } from 'styles';
import { Button, Input, Dropdown, InputAutocomplete } from 'components';
import { REGEXP, STATES } from 'consts';
import { getAutocompleteEmails, getPeopleById } from 'workspace/api';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';

interface EditParticipantFormProps {
  roles: Role[];
  values: WorkspaceMember | null;
  submit: (values: WorkspaceMember) => void;
}

const statesOptions = Object.entries(STATES).map(([value, text]) => ({ value, text }));

const roleTypes = {
  seller: ['Individual', 'LLC', 'Corporation', 'Trust'],
  vendor: ['Photographer', 'Lawn sign maker', 'Lockbox provider', 'Inspector', 'Other']
};
const sellerTypesOptions = roleTypes.seller.map(type => ({ value: type, text: type }));
const vendorTypesOptions = roleTypes.vendor.map(type => ({ value: type, text: type }));

const EditParticipantForm: React.FC<EditParticipantFormProps> = ({ roles, values, submit }) => {
  const { register, watch, errors, control, setValue, handleSubmit } = useForm({
    defaultValues: values ? { ...values } : {}
  });
  const { role, type } = watch(['role', 'type']);

  const rolesOptions = useMemo(() => roles.map(role => ({ value: role.role, text: role.role })), [
    roles
  ]);

  const isSellerOrBuyer = useMemo(
    () =>
      roles.some(item => item.role === role && (item.role === 'Seller' || item.role === 'Buyer')),
    [roles, role]
  );

  const isVendor = useMemo(() => roles.some(item => item.role === role && item.role === 'Vendor'), [
    roles,
    role
  ]);

  const showInfoFields =
    isVendor || (isSellerOrBuyer && type) || (role && !isSellerOrBuyer && !isVendor);

  const getAutocompleteOptions = async text => {
    try {
      const options = await getAutocompleteEmails();
      const filteredOptions = options.filter(item => item.text.toLowerCase().includes(text));
      return filteredOptions;
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const handleEmailAutocompleteSelect = async option => {
    try {
      const member = await getPeopleById(option.id);

      setValue('secondaryEmail', member.secondaryEmail || '');
      setValue('name', member.name || '');
      setValue('phone', member.phone || '');
      setValue('fax', member.fax || '');
      setValue('address1', member.address1 || '');
      setValue('address2', member.address2 || '');
      setValue('businessPhone', member.businessPhone || '');
      setValue('city', member.city || '');
      setValue('companyName', member.companyName || '');
      setValue('state', member.state || '');
      setValue('zip', member.zip || '');
      setValue('contactPerson', member.contactPerson || '');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  return (
    <StyledEditParticipantForm onSubmit={handleSubmit(submit)} className="participant-form">
      <Controller
        control={control}
        rules={{ required: 'Required' }}
        name="role"
        defaultValue=""
        render={controllerProps => (
          <Dropdown
            options={rolesOptions}
            label="Project role"
            placeholder="Select project role"
            error={errors.role?.message}
            className="participant-form__control"
            {...controllerProps}
          />
        )}
      />
      {(isSellerOrBuyer || isVendor) && (
        <Controller
          control={control}
          rules={{ required: isSellerOrBuyer || isVendor ? 'Required' : false }}
          name="type"
          defaultValue=""
          render={controllerProps => (
            <Dropdown
              // eslint-disable-next-line no-nested-ternary
              options={isVendor ? vendorTypesOptions : isSellerOrBuyer ? sellerTypesOptions : []}
              label="Seller type"
              placeholder="Select seller type"
              error={errors.type?.message}
              className="participant-form__control"
              {...controllerProps}
            />
          )}
        />
      )}
      {showInfoFields && (
        <>
          <Controller
            control={control}
            name="email"
            rules={{
              required: 'Required',
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            }}
            defaultValue=""
            render={controllerProps => (
              <InputAutocomplete
                getOptions={getAutocompleteOptions}
                error={errors.email?.message}
                label="Email address"
                placeholder="Enter email"
                className="participant-form__control"
                onOptionSelect={handleEmailAutocompleteSelect}
                {...controllerProps}
              />
            )}
          />

          <Input
            ref={register}
            name="secondaryEmail"
            label="Secondary email address(es)(comma separated)"
            placeholder="Enter email"
            className="participant-form__control"
          />
          {/* Hide company name for seller/buyer */}
          {!isSellerOrBuyer && (
            <Input
              ref={register}
              name="companyName"
              label="Company name"
              placeholder="Enter company name"
              className="participant-form__control"
            />
          )}
          <Input
            ref={register({
              required: 'Required',
              pattern: {
                value: REGEXP.NAME,
                message: 'Invalid name'
              }
            })}
            name="name"
            error={errors.name?.message}
            label="Name"
            placeholder="Enter name"
            className="participant-form__control"
          />
          {/* Show contact person only for not individual seller/buyer  */}
          {isSellerOrBuyer && type !== 'Individual' && (
            <Input
              ref={register({
                pattern: {
                  value: REGEXP.NAME,
                  message: 'Invalid name'
                }
              })}
              name="contactPerson"
              error={errors.contactPerson?.message}
              label="Contact person"
              placeholder="Enter contact person"
              className="participant-form__control"
            />
          )}
          <Controller
            control={control}
            name="phone"
            rules={{
              validate: value => !String(value).includes('_') || 'Invalid phone number'
            }}
            defaultValue=""
            render={controllerProps => (
              <Input
                className="participant-form__control"
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.phone?.message}
                label="Phone Number"
                placeholder="Enter Phone Number"
                {...controllerProps}
              />
            )}
          />
          {/* Hide business phone number if participant is individual seller/buyer */}
          {type !== 'Individual' && (
            <Controller
              control={control}
              name="businessPhone"
              rules={{
                validate: value => !String(value).includes('_') || 'Invalid phone number'
              }}
              defaultValue=""
              render={controllerProps => (
                <Input
                  className="participant-form__control"
                  as={MaskedInput}
                  mask="+1 (999) 999-9999"
                  type="tel"
                  error={errors.businessPhone?.message}
                  label="Phone Number Business"
                  placeholder="Enter Phone Number"
                  {...controllerProps}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="fax"
            rules={{
              validate: value => !String(value).includes('_') || 'Invalid fax number'
            }}
            defaultValue=""
            render={controllerProps => (
              <Input
                className="participant-form__control"
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.fax?.message}
                label="Fax Number"
                placeholder="Enter Fax Number"
                {...controllerProps}
              />
            )}
          />
          <Input
            ref={register}
            name="address1"
            label="Address line 1"
            placeholder="Enter address 1"
            className="participant-form__control"
          />
          <Input
            ref={register}
            name="address2"
            label="Address line 2"
            placeholder="Enter address 2"
            className="participant-form__control"
          />
          <Input
            ref={register}
            name="city"
            label="City"
            placeholder="Enter city"
            className="participant-form__control"
          />
          <Controller
            control={control}
            name="state"
            defaultValue=""
            render={controllerProps => (
              <Dropdown
                options={statesOptions}
                label="State"
                placeholder="Choose state"
                className="participant-form__control"
                {...controllerProps}
              />
            )}
          />
          <Input
            ref={register({
              pattern: {
                value: REGEXP.ZIP_CODE,
                message: 'Invalid postal code'
              }
            })}
            name="zip"
            error={errors.zip?.message}
            label="Postal code"
            placeholder="Enter postal code"
            className="participant-form__control"
          />
        </>
      )}
      <Button className="participant-form__btn">
        {values ? `Save participant` : `Create participant`}
      </Button>
    </StyledEditParticipantForm>
  );
};

export default React.memo(EditParticipantForm);

const StyledEditParticipantForm = styled.form`
  .participant-form {
    &__control {
      display: block;
      margin: 0 0 16px;
      label {
        display: inline-block;
        margin: 0 0 4px;
      }
    }
    &__btn {
      width: 100%;
      height: 40px;
    }
  }
`;
