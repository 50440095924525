import React, { useContext } from 'react';

import { styled } from 'styles';
import { Link } from 'gatsby';

import useAuthContext from 'hooks/useAuthContext';
import Button from 'components/Button';
import Icon from 'components/Icon';
import HeaderAvatarMenu from 'components/HeaderAvatarMenu';
import MainLayoutContext from '../MainLayoutContext';

const Header = () => {
  const { setSidebarOpen } = useContext(MainLayoutContext);
  const { authorized } = useAuthContext();

  return (
    <>
      <StyledHeader>
        <div className="menu-button-container">
          <Button className="menu-btn" simple onClick={() => setSidebarOpen(v => !v)}>
            <Icon name="menu-hamburger" size={24} />
          </Button>
        </div>
        {authorized ? (
          <HeaderAvatarMenu />
        ) : (
          <Link className="auth-link" to="/login">
            Login
          </Link>
        )}
      </StyledHeader>
    </>
  );
};

export default Header;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.grayDark};
  box-sizing: border-box;

  .auth-link {
    color: #000;
    text-decoration: none;
  }

  .menu-button-container {
    margin-right: auto;
  }
`;
