import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { styled } from 'styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { CalendarItem } from 'views/CalendarPage/calendar';

interface TasksTableItemProps extends React.HTMLAttributes<HTMLTableRowElement> {
  data: CalendarItem;
  onCompleteClick: (data: CalendarItem) => void;
  onEditClick: (data: CalendarItem) => void;
  onDeleteClick: (data: CalendarItem) => void;
  onAddOrderClick?: (data: CalendarItem) => void;
}

const TasksTableItem: React.FC<TasksTableItemProps> = ({
  data,
  onCompleteClick,
  onEditClick,
  onDeleteClick,
  onAddOrderClick,
  ...props
}) => {
  const completed = data.dateType === 'task' && data.status === 'Completed';
  const deadlineDate =
    data.start && dayjs(data.start).isValid ? dayjs(data.start).format('MMM DD') : '';

  return (
    <StyledTableRow className={clsx('tasks-table-item', data.dateType, { completed })} {...props}>
      <div className="col title">
        <div className="title-text">{data.title}</div>
      </div>
      <div className="col assignee">
        <Icon name="user" /> {data.assignee || 'N/A'}
      </div>
      <div className="col date">{deadlineDate}</div>
      <div className="col time">
        {data.dateType !== 'task' &&
          `${dayjs(data.start).format('HH:mm A')} - ${dayjs(data.end).format('HH:mm A')}`}
      </div>
      <div className="col location">{data.location || 'N/A'}</div>
      <div className="col">
        <div className="menu-buttons">
          {data.type && (
            <Button
              className="button-order"
              link
              onClick={() => onAddOrderClick?.(data)}
              disabled={data.ordered}>
              {data.ordered ? 'ordered' : '+ order'}
            </Button>
          )}
          {data.dateType === 'task' && (
            <Button
              className="button-complete"
              simple
              title={completed ? 'Undo' : 'Complete'}
              onClick={() => onCompleteClick(data)}>
              <Icon name="check" />
            </Button>
          )}
          <Button simple onClick={() => onDeleteClick(data)} className="button-delete">
            <Icon name="trash" />
          </Button>
          <Button simple onClick={() => onEditClick(data)} className="button-edit">
            <Icon name="pen" />
          </Button>
        </div>
      </div>
    </StyledTableRow>
  );
};

export default TasksTableItem;

const StyledTableRow = styled.div`
  margin-top: 8px;
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: 200px 200px 40px 140px 100px 1fr;
  column-gap: 12px;
  font-size: 12px;
  height: 24px;
  border-radius: 3px;

  &:hover {
    background: #f1f1f1;
  }

  .col {
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.task .col.title {
    position: relative;
    padding-left: 16px;

    &::before {
      background: #62b120;
      content: '';
      position: absolute;
      width: 4px;
      left: 4px;
      top: 4px;
      bottom: 4px;
      border-radius: 4px;
    }
  }

  &.event .title-text {
    padding: 0 4px;
    background: #2979ff;
    color: #fff;
    border-radius: 3px;
    width: 100%;
  }

  &.completed {
    .title,
    .assignee,
    .date,
    .location {
      text-decoration: line-through;
    }

    .button-complete {
      .icon {
        fill: #62b120;
      }
    }
  }

  .icon,
  .indicator {
    vertical-align: text-bottom;
  }

  .icon {
    fill: #8b8b8b;
  }

  .indicator {
    display: inline-block;
    height: 16px;
    width: 4px;
    background: #62b120;
    border-radius: 4px;
    margin-right: 8px;
  }

  .menu-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 24px;
  }

  .button-order {
    cursor: pointer;

    &:disabled {
      background: none;
    }
  }

  .button {
    fill: ${props => props.theme.colors.graphite};
    margin-right: 16px;

    &.button-complete {
      &:focus {
        opacity: 1;
      }
    }

    &.button-delete:hover,
    &.button-edit:hover {
      background: ${props => props.theme.colors.graphite};
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
