import React, { useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import { Button, Icon } from 'components';
// import imagePlaceholder from 'assets/images/avatar.jpg';

interface MembersTableItemProps {
  data: WorkspaceMember;
  edit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  remove: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const MembersTableItem: React.FC<MembersTableItemProps> = ({ data, remove, edit }) => {
  const { businessPhone, secondaryEmail } = data;
  const [open, setOpen] = useState(false);

  return (
    <StyledTableRow className={clsx('member', { open })}>
      <tr onClick={() => setOpen(v => !v)}>
        <td>
          <Icon name="plus-mini" size={10} />
        </td>
        {/* <td>
          <img src={data.photo || imagePlaceholder} alt="member-photo" className="member__photo" />
        </td> */}
        <td>{data.name}</td>
        <td>{data.role}</td>
        <td>
          <span className="member__line">
            <Icon name="smartphone" />
            {data.phone || 'No information'}
          </span>
        </td>
        <td>{data.email}</td>
        <td>{data.companyName}</td>
      </tr>
      {open && (
        <>
          <tr>
            <td colSpan={3} />
            <td>
              <span className="member__line">
                <Icon name="phone" />
                &nbsp;{businessPhone || 'No information'}
              </span>
            </td>
            <td>{secondaryEmail || 'No information'}</td>
          </tr>
          <tr>
            <td colSpan={6}>
              <div className="members__btn-group">
                {/* <Button onClick={() => null} className="member__btn">Send message</Button> */}
                <Button onClick={edit} className="member__btn">
                  Edit user
                </Button>
                <Button secondary onClick={remove} className="member__btn">
                  ✗ Remove
                </Button>
              </div>
            </td>
          </tr>
        </>
      )}
    </StyledTableRow>
  );
};

export default MembersTableItem;

const StyledTableRow = styled.tbody`
  height: 16px;
  color: #545454;

  &.open .icon-plus-mini {
    transform: rotate(45deg);
    fill: ${props => props.theme.colors.red};
  }

  &:not(:last-of-type) tr:last-of-type td {
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  tr:first-of-type {
    cursor: pointer;
  }

  td {
    padding: 8px 0;
  }

  .member {
    &__photo {
      width: 24px;
      height: 24px;
      object-fit: contain;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__line {
      display: inline-flex;
      align-items: center;
    }
    &__no-info {
      border-radius: ${props => props.theme.colors.grayDark};
    }
  }
`;
