import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Button, Modal } from 'components';
import { styled } from 'styles';
import notify from 'notify';
import useLayout from 'hooks/useLayout';
import { tryGetFirstError } from 'utils/requests';
import getRoles from 'api/roles';
import { updateParticipant, removeParticipant } from 'workspace/api';
import WorkspaceContext from 'workspace/WorkspaceContext';
import MembersTableItem from './MembersTableItem';
import MobileMembersTableItem from './MobileMembersTableItem';
import EditParticipantForm from './EditParticipantForm';

const WorkspaceMembersTab = () => {
  const { listingId, members, setMembers, fetchMembers } = useContext(WorkspaceContext);
  const [roles, setRoles] = useState<Role[]>([]);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [editParticipantData, setEditParticipantData] = useState<WorkspaceMember | null>(null);
  const layout = useLayout();
  const [deleteParticipantData, setDeleteParticipantData] = useState<number | null>(null);
  const roleGroups = useMemo(() => {
    const group = {};
    group['Sell Side'] = members.filter(member => member.role?.includes('Seller'));
    group['Buy Side'] = members.filter(member => member.role?.includes('Buyer'));
    // eslint-disable-next-line dot-notation
    group['Vendor'] = members.filter(member => member.role?.includes('Vendor'));
    return group;
  }, [members]);

  const fetchRoles = useCallback(async () => {
    try {
      const roles = await getRoles();
      setRoles(roles);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoles]);

  const editParticipant = async (values: WorkspaceMember) => {
    try {
      const isEditing = Boolean(editParticipantData);
      const formValues = isEditing
        ? { ...values, peopleId: editParticipantData?.id, listingId }
        : { ...values, listingId };
      await updateParticipant(formValues);
      const notifyMessage = isEditing
        ? 'Participant was successfully updated'
        : 'New participant was successfully added';
      setOpenEditForm(false);
      fetchMembers();
      setEditParticipantData(null);
      notify(notifyMessage);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const showEditForm = (member: WorkspaceMember) => {
    setEditParticipantData(member);
    setOpenEditForm(true);
  };

  const closeEditForm = () => {
    setOpenEditForm(false);
    setEditParticipantData(null);
  };

  const closeDeleteParticipant = () => setDeleteParticipantData(null);

  const handleRemoveParticipant = async () => {
    try {
      const memberId = deleteParticipantData;
      await removeParticipant(listingId, memberId);
      setMembers(value => value.filter(item => item.id !== memberId));
      closeDeleteParticipant();
      notify('Participant was successfully removed');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    if (!members.length) fetchMembers();
    if (!roles.length) fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMembers, fetchRoles]);

  const ListItemComponent = useMemo(
    () => (layout === 'mobile' ? MobileMembersTableItem : MembersTableItem),
    [layout]
  );

  return (
    <>
      <StyledWorkspaceMembersTab>
        <div className="members__btn-group">
          <Button onClick={() => setOpenEditForm(true)} className="members__btn">
            Add new
          </Button>
        </div>
        {members.length !== 0 && (
          <table>
            {Object.keys(roleGroups)
              .filter(group => roleGroups[group].length)
              .map(group => (
                <>
                  <tbody>
                    <tr>
                      <td key={group} className="members__title" colSpan={7}>
                        {group}
                      </td>
                    </tr>
                  </tbody>

                  {roleGroups[group].map(member => (
                    <ListItemComponent
                      key={member.id}
                      data={member}
                      edit={() => showEditForm(member)}
                      remove={() => setDeleteParticipantData(member.id)}
                    />
                  ))}
                </>
              ))}
          </table>
        )}
        {members.length === 0 && <div className="not-found-message">No members yet.</div>}
      </StyledWorkspaceMembersTab>
      <Modal
        modalTitle={`${editParticipantData ? 'Edit' : 'Add'} participant`}
        open={openEditForm}
        onClose={closeEditForm}>
        <EditParticipantForm roles={roles} values={editParticipantData} submit={editParticipant} />
      </Modal>
      <StyledModal
        modalTitle="Remove participant"
        open={Boolean(deleteParticipantData)}
        onClose={closeDeleteParticipant}
        className="delete-member">
        <p>Are you sure you want to remove this participant? This action can’t be undone.</p>
        <Button secondary onClick={closeDeleteParticipant}>
          Discard
        </Button>
        <Button onClick={handleRemoveParticipant}>Remove</Button>
      </StyledModal>
    </>
  );
};

export default WorkspaceMembersTab;

const StyledWorkspaceMembersTab = styled.div`
  margin: 16px 0;
  table {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    border-collapse: collapse;

    th {
      font-weight: normal;
      color: #8b8b8b;
      fill: #8b8b8b;
      line-height: 16px;
      text-align: left;
      padding: 16px 0;
    }

    th,
    td {
      &:first-of-type {
        width: 32px;
        text-align: center;
        &.members__title {
          padding-top: 32px;
          width: 100%;
          text-align: left;
        }
      }
    }

    thead:first-of-type th {
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
  }
  .members {
    &__title {
      margin: 8px 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    &__btn-group {
      display: flex;
      justify-content: flex-end;
      .button ~ .button {
        margin-left: 16px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .members {
      &__btn {
        width: 100%;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .button {
    width: 100%;
    height: 40px;
  }
  .button + .button {
    margin-top: 16px;
  }
`;
