import React, { useCallback, useEffect, useState } from 'react';
import { getListing } from 'api/listings';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { getMembers } from './api';

interface WorkspaceContextValues {
  workspaceOpen: boolean;
  openWorkspace: (listing: Listing) => void;
  closeWorkspace: () => void;
  listing: Listing | null;
  listingId: string;
  workspaceTab: WorkspaceTabKey;
  setWorkspaceTab: (workspaceTab: WorkspaceTabKey) => void;
  fetchMembers: () => Promise<void>;
  members: WorkspaceMember[];
  setMembers: React.Dispatch<React.SetStateAction<WorkspaceMember[]>>;
}

const WorkspaceContext = React.createContext({} as WorkspaceContextValues);

const defaultTab: WorkspaceTabKey = 'documents';

export const WorkspaceContextWrapper = ({ children }) => {
  const [query, setQuery] = useQueryParams({
    listingId: withDefault(StringParam, ''),
    workspaceTab: withDefault(StringParam, defaultTab) as QueryParamConfig<WorkspaceTabKey>
  });
  const [members, setMembers] = useState<WorkspaceMember[]>([]);
  const { listingId, workspaceTab } = query;

  const [listing, setListing] = useState<Listing | null>(null);
  const workspaceOpen = Boolean(listing);

  const setWorkspaceTab = (workspaceTab: WorkspaceTabKey) => {
    setQuery({ workspaceTab }, 'replaceIn');
  };

  const openWorkspace = (listing: Listing) => {
    setListing(listing);
    setQuery({ listingId: listing.id, workspaceTab: defaultTab }, 'replaceIn');
  };

  const closeWorkspace = () => {
    setListing(null);
    setQuery({ listingId: undefined, workspaceTab: undefined });
    setMembers([]);
  };

  const fetchMembers = useCallback(async () => {
    if (!listingId) return;
    try {
      const members = await getMembers(listingId);
      setMembers(members);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  const fetchListing = useCallback(async () => {
    try {
      const listing = await getListing(listingId!);
      setListing(listing);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  }, [listingId]);

  useEffect(() => {
    if (listingId && !listing) fetchListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId, fetchListing]);

  const values: WorkspaceContextValues = {
    openWorkspace,
    closeWorkspace,
    workspaceOpen,
    workspaceTab,
    setWorkspaceTab,
    listing,
    listingId,
    fetchMembers,
    members,
    setMembers
  };
  return <WorkspaceContext.Provider value={values}>{children}</WorkspaceContext.Provider>;
};

export default WorkspaceContext;
