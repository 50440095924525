import React, { useState } from 'react';
import dayjs from 'dayjs';

import { styled } from 'styles';
import { Button, Checkbox, Icon } from 'components';
import documentTypes from 'utils/documentTypes.json';

interface DocumentsTableItemProps extends React.HTMLAttributes<HTMLTableRowElement> {
  data: WorkspaceDocument;
  selected: boolean;
  onShareClick: (data: WorkspaceDocument) => void;
  onDeleteClick: (data: WorkspaceDocument) => void;
  onSelectChange: (event: React.ChangeEvent<HTMLInputElement>, documentId: number) => void;
}

const DocumentsTableItem: React.FC<DocumentsTableItemProps> = ({
  data,
  selected,
  onShareClick,
  onDeleteClick,
  onSelectChange,
  ...props
}) => {
  return (
    <StyledTableRow className="document-row" {...props}>
      <td className="document-row__type">
        <Checkbox
          checked={selected}
          onChange={e => onSelectChange(e, data.id)}
          label={data.doctype && documentTypes[data.doctype - 1].name}
        />
      </td>
      <td className="document-row__name">
        <a href={data.link} download target="_blank" rel="noreferrer">
          {data.name} {data.draft && <span className="draft-pill">Draft</span>}
        </a>
      </td>
      <td className="document-row__date">{dayjs(data.createdOn).format('MM/DD/YYYY')}</td>
      <td className="document-row__menu">
        <div className="menu-buttons">
          {/* share */}
          <Button simple title="Share" onClick={() => onShareClick(data)}>
            <Icon name="upload" />
          </Button>
          {/* Change type */}
          <Button simple title="Edit">
            <Icon name="pen" />
          </Button>
          <Button simple title="Delete" onClick={() => onDeleteClick(data)}>
            <Icon name="trash" />
          </Button>
        </div>
      </td>
    </StyledTableRow>
  );
};

export default DocumentsTableItem;

const StyledTableRow = styled.tr`
  height: 16px;

  td {
    padding: 8px 0;
  }

  .draft-pill {
    color: ${props => props.theme.colors.red};
    background: ${props => props.theme.colors.seashell};
    padding: 0 4px;
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .document-row__name {
    a {
      text-decoration: none;
      color: unset;
    }

    .checkbox {
      font-weight: 500;
    }
  }

  .document-row__type {
    padding-left: 24px;
  }

  .document-row__type,
  .document-row__date {
    color: ${props => props.theme.colors.grayDark};
  }

  .document-row__menu {
    width: 16px;

    .menu-buttons {
      display: flex;
      justify-content: flex-end;
    }

    .button {
      fill: ${props => props.theme.colors.graphite};
      margin: 0 8px;
    }
  }
`;
