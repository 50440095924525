import React, { useMemo } from 'react';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import { Button, Checkbox, ModalConfirm } from 'components';
import DocumentsTableItem from './DocumentsTableItem';
import useDocuments from './useDocuments';
import UploadFileMenu from './UploadFileMenu';
import ShareDocumentModal from './ShareDocumentModal';

const WorkspaceDocumentsTab = () => {
  const {
    contractAndAmendments,
    deleteDocument,
    deleteDocumentData,
    deleteSelectedDocuments,
    disableContractBuilderButton,
    documents,
    downloadSelectedDocuments,
    finalClosingDocuments,
    handleDocumentSelectionChange,
    handleDocumentsGroupSelectionChange,
    handleFileUploadSuccess,
    isDownloading,
    listing,
    listingDocuments,
    openContractBuilder,
    selectedDocuments,
    selectedDocumentCategories,
    setDeleteDocumentData,
    setShareDocumentData,
    setUploadMenuOpen,
    shareDocumentData,
    shareSelectedDocuments,
    uploadMenuOpen,
    disableActions
  } = useDocuments();
  const layout = useLayout();

  const buttonGroup = useMemo(
    () => (
      <div className="btn-group">
        <Button secondary onClick={openContractBuilder} disabled={disableContractBuilderButton}>
          Сreate new
        </Button>
        <Button className="upload" onClick={() => setUploadMenuOpen(true)}>
          Upload
        </Button>
      </div>
    ),
    [openContractBuilder, disableContractBuilderButton, setUploadMenuOpen]
  );

  if (!listing) return null;

  return (
    <StyledWorkspaceDocumentsTab className="documents-table">
      {!uploadMenuOpen && (
        <>
          {layout === 'mobile' && buttonGroup}
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>{layout !== 'mobile' && buttonGroup}</th>
                </tr>
              </thead>
              {documents.length !== 0 && (
                <>
                  {contractAndAmendments.length !== 0 && (
                    <>
                      <thead>
                        <tr>
                          <th>
                            <Checkbox
                              value="contract_amendments"
                              checked={selectedDocumentCategories.includes('contract_amendments')}
                              onChange={handleDocumentsGroupSelectionChange}
                              label="Contracts & Amendments"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {contractAndAmendments.map(document => (
                          <DocumentsTableItem
                            key={document.id}
                            data={document}
                            selected={selectedDocuments.includes(document.id)}
                            onDeleteClick={document => setDeleteDocumentData([document.id])}
                            onSelectChange={handleDocumentSelectionChange}
                            onShareClick={document => setShareDocumentData([document.id])}
                          />
                        ))}
                      </tbody>
                    </>
                  )}
                  {listingDocuments.length !== 0 && (
                    <>
                      <thead>
                        <tr>
                          <th>
                            <Checkbox
                              value="listing_documents"
                              checked={selectedDocumentCategories.includes('listing_documents')}
                              onChange={handleDocumentsGroupSelectionChange}
                              label="Listing Documents"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listingDocuments.map(document => (
                          <DocumentsTableItem
                            key={document.id}
                            data={document}
                            selected={selectedDocuments.includes(document.id)}
                            onDeleteClick={document => setDeleteDocumentData([document.id])}
                            onSelectChange={handleDocumentSelectionChange}
                            onShareClick={document => setShareDocumentData([document.id])}
                          />
                        ))}
                      </tbody>
                    </>
                  )}
                  {finalClosingDocuments.length !== 0 && (
                    <>
                      <thead>
                        <tr>
                          <th>
                            <Checkbox
                              value="final_closing_documents"
                              checked={selectedDocumentCategories.includes(
                                'final_closing_documents'
                              )}
                              onChange={handleDocumentsGroupSelectionChange}
                              label="Final Closing Documents"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {finalClosingDocuments.map(document => (
                          <DocumentsTableItem
                            key={document.id}
                            data={document}
                            selected={selectedDocuments.includes(document.id)}
                            onDeleteClick={document => setDeleteDocumentData([document.id])}
                            onSelectChange={handleDocumentSelectionChange}
                            onShareClick={document => setShareDocumentData([document.id])}
                          />
                        ))}
                      </tbody>
                    </>
                  )}
                </>
              )}
            </table>
            {documents.length === 0 && <div className="not-found-message">No documents yet.</div>}
          </div>
          {selectedDocuments.length !== 0 && (
            <div className="selected-documents-buttons">
              <Button secondary onClick={() => setDeleteDocumentData(selectedDocuments)}>
                Delete
              </Button>
              <Button secondary onClick={() => setShareDocumentData(selectedDocuments)}>
                Share
              </Button>
              <Button onClick={downloadSelectedDocuments} disabled={isDownloading}>
                {isDownloading ? 'Downloading...' : 'Download'}
              </Button>
            </div>
          )}
        </>
      )}
      {uploadMenuOpen && (
        <UploadFileMenu
          listingId={listing.id}
          onSuccess={handleFileUploadSuccess}
          onCancel={() => setUploadMenuOpen(false)}
        />
      )}

      {/* {layout === 'desktop' ? (
        uploadMenuOpen && (
          <UploadFileMenu
            listingId={listing.id}
            onSuccess={handleFileUploadSuccess}
            onCancel={() => setUploadMenuOpen(false)}
          />
        )
      ) : (
        <UploadFileModal
          listingId={listing.id}
          open={uploadMenuOpen}
          onClose={() => setUploadMenuOpen(false)}
          onSuccess={handleFileUploadSuccess}
        />
      )} */}
      <ShareDocumentModal
        open={Boolean(shareDocumentData?.length)}
        onClose={() => setShareDocumentData(undefined)}
        disableActions={disableActions}
        onMemberSelected={member => {
          shareSelectedDocuments([member.id]);
        }}
      />
      <StyledDeletionModal
        modalTitle="Delete document"
        open={Boolean(deleteDocumentData)}
        onClose={() => setDeleteDocumentData(undefined)}
        onSubmit={() => {
          if (deleteDocumentData!.length > 1) deleteSelectedDocuments();
          else deleteDocument();
        }}>
        Are you sure you wan to delete{' '}
        {deleteDocumentData?.length ? 'these documents' : 'this document'}? This action can't be
        undone
      </StyledDeletionModal>
    </StyledWorkspaceDocumentsTab>
  );
};

export default WorkspaceDocumentsTab;

const StyledWorkspaceDocumentsTab = styled.div`
  &.documents-table {
    flex: 1;
  }
  table {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 100%;

    th {
      font-weight: normal;
      color: #8b8b8b;
      line-height: 16px;
      text-align: left;
      padding: 16px 0;
      .button + .button {
        margin-left: 10px;
      }
    }

    thead:first-of-type th {
      border-bottom: 1px solid ${props => props.theme.colors.seashell};

      &:last-of-type {
        text-align: end;
        width: 160px;
      }
    }
  }

  .table-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }

  .selected-documents-buttons {
    position: sticky;
    display: flex;
    align-items: center;
    bottom: -24px;
    height: 60px;
    margin: 0 -20px;
    padding: 8px 20px;
    background: #f8f8f8;

    .button:nth-of-type(1) {
      margin-right: auto;
    }
    .button:nth-of-type(2) {
      margin-right: 10px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .btn-group {
      margin: 16px 0;
      display: flex;
      .button {
        flex: 1;
      }
      .button + .button {
        margin-left: 8px;
      }
    }
  }
`;

const StyledDeletionModal = styled(ModalConfirm)`
  .modal-content {
    max-width: 400px;

    .doc-name {
      white-space: nowrap;
    }
  }
`;
