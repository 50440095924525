import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Icon } from 'components';
import OfferDetails from 'components/OfferDetails';
import { styled } from 'styles';
import { formatCurrency } from 'utils/rifmFormatters';
import clsx from 'clsx';

interface OffersTableItemProps {
  data: WorkspaceOffer;
  openPopup: (value: { type: 'Accept' | 'Reject'; data: WorkspaceOffer; }) => void;
}

const OffersTableItem: React.FC<OffersTableItemProps> = ({ data, openPopup }) => {
  const { formData } = data;
  const [open, setOpen] = useState(false);
  const downloadLink = data.link || data.formData.official_offer[0]?.url;

  return (
    <StyledTableRow className={clsx('offer', { open })}>
      <tr onClick={() => setOpen(v => !v)}>
        <td>
          <Icon name="plus-mini" size={10} />
        </td>
        <td>{formatCurrency(formData.offerAmount)}</td>
        <td>{data.name || formData.buyer_1_name}</td>
        <td
          className={clsx(
            'status',
            { active: data.status === 'Active' },
            { accepted: data.status === 'Accepted' },
            { rejected: data.status === 'Rejected' }
          )}>
          <span className="status-info">
            {data.status === 'Accepted' && <Icon name="check" size={12} className="status-icon" />}
            {data.status}
          </span>
        </td>
        <td>{dayjs.unix(data.created).format('MM/DD/YYYY')}</td>
        <td>
          <a
            href={downloadLink}
            target="_blank"
            rel="noreferrer"
            className="download-link"
            onClick={e => e.stopPropagation()}>
            <Icon name="download" size={12} />
          </a>
        </td>
      </tr>
      {open && (
        <tr>
          <td />
          <td colSpan={5}>
            <OfferDetails data={data} openPopup={openPopup} />
          </td>
        </tr>
      )}
    </StyledTableRow>
  );
};

export default OffersTableItem;

const StyledTableRow = styled.tbody`
  height: 16px;
  color: #545454;

  &.open .icon-plus-mini {
    transform: rotate(45deg);
    fill: ${props => props.theme.colors.red};
  }

  &:not(:last-of-type) tr:last-of-type td {
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  tr:first-of-type {
    cursor: pointer;
  }

  td {
    padding: 8px 0;
  }

  .status {
    color: #8b8b8b;
    &.active {
      color: #2979ff;
    }
    &.accepted {
      color: #62b120;
    }
    &.rejected {
      color: #df1721;
    }
  }
  .status-info {
    display: flex;
    align-items: center;
  }
  .status-icon {
    fill: #62b120;
    margin-right: 5px;
  }
  .download-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-left: auto;
    background: ${props => props.theme.colors.pink};
    border-radius: ${props => props.theme.misc.borderRadius};
    .icon {
      fill: ${props => props.theme.colors.red};
    }
  }
`;
