import React, { useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import { Button, Icon } from 'components';
// import imagePlaceholder from 'assets/images/avatar.jpg';

interface MobileMembersTableItemProps {
  data: WorkspaceMember;
  edit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  remove: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const MobileMembersTableItem: React.FC<MobileMembersTableItemProps> = ({ data, remove, edit }) => {
  const { businessPhone, secondaryEmail } = data;
  const [open, setOpen] = useState(false);

  return (
    <StyledTableRow className={clsx('member', { open })}>
      <div onClick={() => setOpen(v => !v)} className="member__header">
        <span className="member__col">
          <Icon name="plus-mini" size={10} />
        </span>
        {/* <span className="member__col">
          <img src={data.photo || imagePlaceholder} alt="member-photo" className="member__photo" />
        </span> */}
        <span className="member__col name">{data.name}</span>
        <span className="member__col role">{data.role}</span>
      </div>
      {open && (
        <div className="member__content">
          <div className="member__line">{data.companyName}</div>
          <div className="member__line">
            <Icon name="smartphone" />
            {data.phone || 'No information'}
          </div>
          <div className="member__line">
            <Icon name="phone" />
            &nbsp;{businessPhone || 'No information'}
          </div>
          <div className="member__line">
            {secondaryEmail || <div className="member__no-info member__line">No information</div>}
          </div>
          <div className="members__btn-group">
            {/* <Button onClick={() => null} className="member__btn">Send message</Button> */}
            <Button onClick={edit} className="member__btn">
              Edit user
            </Button>
            <Button secondary onClick={remove} className="member__btn">
              ✗ Remove
            </Button>
          </div>
        </div>
      )}
    </StyledTableRow>
  );
};

export default MobileMembersTableItem;

const StyledTableRow = styled.tbody`
  height: 16px;
  color: #545454;

  &.open .icon-plus-mini {
    transform: rotate(45deg);
    fill: ${props => props.theme.colors.red};
  }

  .member {
    &__header {
      display: flex;
      align-items: center;
    }
    &__content {
      margin: 16px 0;
      padding: 16px 0;
      border-top: 1px solid ${props => props.theme.colors.seashell};
    }
    &__col {
      padding: 0 8px;
      &.name,
      &.role {
        flex: 2;
      }
    }
    &__line {
      display: flex;
      margin-bottom: 16px;
    }
    &__photo {
      width: 24px;
      height: 24px;
      object-fit: contain;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__no-info {
      border-radius: ${props => props.theme.colors.grayDark};
    }
  }
  .role {
    display: inline-block;
    margin-left: 54px;
  }
`;
