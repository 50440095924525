import { useCallback, useContext, useEffect, useState } from 'react';
import { capitalize } from 'lodash';

import notify from 'notify';
import { getUsers } from 'api/users';
import { mapUsers } from 'views/CalendarPage/mapping';
import { tryGetFirstError } from 'utils/requests';
import { DropdownOption } from 'components';
import { CalendarItem } from 'views/CalendarPage/calendar';
import {
  completeTask as completeTaskRequest,
  deleteEvent,
  deleteTask
} from 'views/CalendarPage/api';
import WorkspaceContext from '../WorkspaceContext';
import { addNote, getNotes, getWorksapceCalendarData } from '../api';

export default () => {
  const { listingId } = useContext(WorkspaceContext);
  const [calendarData, setCalendarData] = useState<CalendarItem[]>();
  const [notes, setNotes] = useState<WorkspaceNote[]>();
  const [calendarEdit, setCalendarEdit] = useState<Partial<CalendarItem>>();
  const [calendarDelete, setCalendarDelete] = useState<CalendarItem>();
  const [documentsOptions, setDocumentsOptions] = useState<DropdownOption[]>([]);
  const [usersOptions, setUsersOptions] = useState<DropdownOption[]>([]);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [addNoteText, setAddNoteText] = useState('');
  const [addOrderModalData, setAddOrderModalData] = useState<CalendarItem>();

  const cancelAddNote = () => {
    setIsAddingNote(false);
    setAddNoteText('');
  };

  const removeNote = noteId => {
    setNotes(notes => notes?.filter(item => item.id !== noteId));
  };

  const submitNewNote = async e => {
    if (addNoteText) e.preventDefault();
    try {
      const notes = await addNote({ listingId, text: addNoteText });
      setNotes(notes);
      cancelAddNote();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const completeTask = async event => {
    try {
      await completeTaskRequest(event);
      fetchTasks();
      notify('Task was successfully updated');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const deleteCalendarItem = async event => {
    try {
      const deleteRequest = event?.dateType === 'task' ? deleteTask : deleteEvent;
      await deleteRequest(event);
      fetchTasks();
      notify(`${capitalize(calendarDelete?.dateType)} successfully deleted`);
      setCalendarDelete(undefined);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const fetchNotes = useCallback(async () => {
    try {
      setNotes(await getNotes(listingId));
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  const fetchTasks = useCallback(async () => {
    try {
      const { calendar, documents } = await getWorksapceCalendarData(listingId);
      setCalendarData(calendar);
      setDocumentsOptions(documents);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  useEffect(() => {
    (async () => {
      try {
        const users = await getUsers();
        const mappedData = mapUsers(users);
        setUsersOptions(mappedData);
      } catch (err) {
        notify(err.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (!listingId) return;
    fetchNotes();
    fetchTasks();
  }, [fetchNotes, fetchTasks, listingId]);

  return {
    listingId,
    calendarData,
    notes,
    calendarEdit,
    setCalendarEdit,
    calendarDelete,
    setCalendarDelete,
    documentsOptions,
    usersOptions,
    isAddingNote,
    setIsAddingNote,
    addNoteText,
    setAddNoteText,
    addOrderModalData,
    setAddOrderModalData,
    removeNote,
    submitNewNote,
    cancelAddNote,
    completeTask,
    fetchTasks,
    deleteCalendarItem
  };
};
