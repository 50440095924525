import { useState } from 'react';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getContractBuilderToken } from 'api/contract-builder';

export default (): [boolean, () => void] => {
  const [disableContractBuilderButton, setDisableContractBuilderButton] = useState<boolean>(false);

  const openContractBuilder = () => {
    // Fix for Safari
    const windowReference = window.open();

    (async () => {
      setDisableContractBuilderButton(true);
      try {
        const token = await getContractBuilderToken();
        windowReference.location = `https://contracts.mybrokertools.com/validate/${token}`;
      } catch (err) {
        notify(tryGetFirstError(err.response) || err.message);
      }
      setDisableContractBuilderButton(false);
    })();
  };

  return [disableContractBuilderButton, openContractBuilder];
};
