import { Button, /* Image, */ Modal, ModalProps } from 'components';
import React, { useContext, useEffect, useMemo } from 'react';
import { styled } from 'styles';
// import placeholderUser from 'assets/icons/placeholder-user.svg';
import WorkspaceContext from '../WorkspaceContext';

interface ShareDocumentModalProps extends Omit<ModalProps, 'children'> {
  disableActions?: boolean;
  onMemberSelected: (member: WorkspaceMember) => void;
}

const ShareDocumentModal: React.FC<ShareDocumentModalProps> = ({
  disableActions,
  onMemberSelected,
  ...props
}) => {
  const { members, fetchMembers } = useContext(WorkspaceContext);
  const roleGroups = useMemo(() => {
    const group = {};
    group['Sell Side'] = members.filter(member => member.role?.includes('Seller'));
    group['Buy Side'] = members.filter(member => member.role?.includes('Buyer'));
    // eslint-disable-next-line dot-notation
    group['Vendor'] = members.filter(member => member.role?.includes('Vendor'));
    return group;
  }, [members]);

  useEffect(() => {
    if (!members.length) fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMembers]);

  return (
    <StyledShareDocumentModal
      className="share-document-modal"
      modalTitle="Share Document"
      {...props}>
      {Object.entries(roleGroups)
        .filter(([, members]) => (members as WorkspaceMember[]).length > 0)
        .map(([group, members]) => (
          <React.Fragment key={group}>
            <h4 className="users-title">{group}:</h4>
            <div className="users">
              {(members as WorkspaceMember[]).map(member => (
                <div className="user-item" key={member.id}>
                  <div className="user">
                    {/* <Image
                      className="user__avatar"
                      src={placeholderUser}
                      size="32x32"
                      alt="user-avatar"
                    /> */}
                    <div className="user__info">
                      <span className="user__name">{member.name}</span>
                      <span className="user__role">{member.role}</span>
                    </div>
                  </div>
                  <Button
                    secondary
                    disabled={disableActions}
                    onClick={() => onMemberSelected(member)}>
                    Share
                  </Button>
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
    </StyledShareDocumentModal>
  );
};

export default ShareDocumentModal;

const StyledShareDocumentModal = styled(Modal)`
  .users-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 20px;
  }
  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
  }
  .user {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    &__avatar {
      margin: 0 12px 0 0;
      flex-shrink: 0;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__name {
      margin: 0 0 2px;
    }
    &__name,
    &__role {
      display: block;
    }
    &__role {
      color: #8b8b8b;
    }
  }
`;
