import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'components';
import { styled } from 'styles';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getOffers, changeOfferStatus } from 'workspace/api';
import WorkspaceContext from 'workspace/WorkspaceContext';
import OffersTableItem from './OffersTableItem';

type OfferPopupData = {
  type: 'Accept' | 'Reject';
  data: WorkspaceOffer;
};

const WorkspaceOffersTab = () => {
  const { listingId } = useContext(WorkspaceContext);
  const [offers, setOffers] = useState<WorkspaceOffer[]>([]);
  const [offerPopupData, setOfferPopupData] = useState<OfferPopupData | undefined>();

  const fetchOffers = useCallback(async () => {
    try {
      const offers = await getOffers(listingId);
      setOffers(offers);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, [listingId]);

  const closeOfferPopup = () => setOfferPopupData(undefined);

  const submitOfferStatus = async () => {
    try {
      const offerStatus = `${offerPopupData?.type}ed`;
      const offerPayload = { ...(offerPopupData?.data as WorkspaceOffer), status: offerStatus };
      await changeOfferStatus(offerPayload);
      setOfferPopupData(undefined);
      fetchOffers();
      notify(`Offer ${offerStatus.toLocaleLowerCase()}`);
    } catch (err) {
      notify(err.message);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return (
    <>
      <StyledWorkspaceOffersTab>
        <table>
          <thead>
            <tr>
              <th>+</th>
              <th>Amount</th>
              <th>Buyer</th>
              <th>Status</th>
              <th>Date</th>
              <th className="download">
                <Icon name="download" size={8} />
              </th>
            </tr>
          </thead>
          {offers.length !== 0 && (
            <>
              {offers.map(offer => (
                <OffersTableItem key={offer.id} data={offer} openPopup={setOfferPopupData} />
              ))}
            </>
          )}
        </table>
        {offers.length === 0 && <div className="not-found-message">No offers yet.</div>}
      </StyledWorkspaceOffersTab>
      <StyledModal
        modalTitle={`${offerPopupData?.type} offer`}
        open={Boolean(offerPopupData)}
        onClose={closeOfferPopup}
        className="delete-member">
        <p>Are you sure want to {offerPopupData?.type.toLocaleLowerCase()} the offer?</p>
        <Button secondary onClick={closeOfferPopup}>
          Cancel
        </Button>
        <Button onClick={submitOfferStatus}>{offerPopupData?.type}</Button>
      </StyledModal>
    </>
  );
};

export default WorkspaceOffersTab;

const StyledWorkspaceOffersTab = styled.div`
  table {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    border-collapse: collapse;

    th {
      font-weight: normal;
      color: #8b8b8b;
      fill: #8b8b8b;
      line-height: 16px;
      text-align: left;
      padding: 16px 0;
    }

    th,
    td {
      &:nth-of-type(1),
      &:nth-of-type(6) {
        width: 32px;
        text-align: center;
      }
    }

    thead:first-of-type th {
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .button {
    width: 100%;
    height: 40px;
  }
  .button + .button {
    margin-top: 16px;
  }
`;
