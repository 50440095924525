import React from 'react';
import { capitalize } from 'lodash';

import { styled } from 'styles';
import { Button, Input, Modal, ModalConfirm } from 'components';
import TasksTableItem from './TasksTableItem';
import WorkspaceNote from './WorkspaceNote';
import WorkspaceEditEventModal from './WorkspaceEventEditModal';
import AddOrderForm from './AddOrderForm';
import useTasks from './useTasks';

const WorkspaceTasksTab = () => {
  const {
    listingId,
    calendarData,
    notes,
    calendarEdit,
    setCalendarEdit,
    calendarDelete,
    setCalendarDelete,
    documentsOptions,
    usersOptions,
    isAddingNote,
    setIsAddingNote,
    addNoteText,
    setAddNoteText,
    addOrderModalData,
    setAddOrderModalData,
    removeNote,
    submitNewNote,
    cancelAddNote,
    completeTask,
    fetchTasks,
    deleteCalendarItem
  } = useTasks();

  if (!listingId) return null;

  return (
    <StyledWorkspaceTasksTab>
      <h3>Notes</h3>
      {notes && notes.length !== 0 && (
        <>
          {notes?.map(item => (
            <WorkspaceNote key={item.id} data={item} onDelete={removeNote} />
          ))}
        </>
      )}
      {isAddingNote ? (
        <form className="add-note" onSubmit={submitNewNote}>
          <Input
            as="textarea"
            value={addNoteText}
            onChange={e => setAddNoteText(e.target.value)}
            required
          />
          <Button secondary type="button" onClick={cancelAddNote}>
            Cancel
          </Button>
          <Button onClick={submitNewNote}>Add</Button>
        </form>
      ) : (
          <Button link onClick={() => setIsAddingNote(true)}>
            + Add note
          </Button>
        )}
      {!notes ||
        (notes.length === 0 && <div className="not-found-message notes">No notes yet.</div>)}

      <div>
        <Button className="add-task-button" onClick={() => setCalendarEdit({})}>
          Add new task
        </Button>
        <h3>Tasks</h3>
      </div>
      <div className="table">
        <div className="table__header">
          <div>Name</div>
          <div>Assignee</div>
          <div>Date</div>
          <div>Time</div>
          <div>Location</div>
        </div>
        {calendarData && calendarData.length !== 0 && (
          <>
            {calendarData?.map(item => (
              <TasksTableItem
                key={item.id}
                data={item}
                onCompleteClick={completeTask}
                onEditClick={setCalendarEdit}
                onDeleteClick={() => setCalendarDelete(item)}
                onAddOrderClick={setAddOrderModalData}
              />
            ))}
          </>
        )}
      </div>
      {calendarData && calendarData.length === 0 && (
        <div className="not-found-message">No tasks yet.</div>
      )}
      <WorkspaceEditEventModal
        propertyId={listingId}
        open={Boolean(calendarEdit)}
        data={calendarEdit}
        dateType={calendarEdit?.dateType}
        documentsOptions={documentsOptions}
        usersOptions={usersOptions}
        onClose={() => setCalendarEdit(undefined)}
        onDeleteClick={setCalendarDelete}
        onEditSuccess={() => {
          setCalendarEdit(undefined);
          fetchTasks();
        }}
      />
      <ModalConfirm
        open={Boolean(calendarDelete)}
        modalTitle={`Delete ${calendarDelete?.dateType}`}
        onClose={() => setCalendarDelete(undefined)}
        onSubmit={() => deleteCalendarItem(calendarDelete)}>
        <p>{`${capitalize(
          calendarDelete?.dateType
        )} will be removed completely from the system.`}</p>
      </ModalConfirm>
      <Modal
        open={Boolean(addOrderModalData)}
        modalTitle="Order"
        onClose={() => setAddOrderModalData(undefined)}>
        <AddOrderForm onSuccess={() => {
          setAddOrderModalData(undefined);
          fetchTasks();
        }} task={addOrderModalData} />
      </Modal>
    </StyledWorkspaceTasksTab>
  );
};

export default WorkspaceTasksTab;

const StyledWorkspaceTasksTab = styled.div`
  margin-top: 24px;

  .add-note {
    .input {
      width: 100%;
      margin-bottom: 12px;

      textarea {
        width: 100%;
        resize: none;
      }
    }

    .button {
      margin-right: 8px;
    }
  }

  .add-task-button {
    float: right;
  }
  .not-found-message {
    margin: 64px 0;
  }

  h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  & > .button.link {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .table {
    display: grid;
    column-gap: 12px;
    font-size: 12px;
    overflow-x: auto;
    box-sizing: border-box;
    width: 100%;

    &__header {
      padding: 8px 0;
      grid-column: 1 / span 6;
      display: grid;
      grid-template-columns: 200px 200px 40px 140px 100px 1fr;
      column-gap: 12px;
      border-bottom: 1px solid #dadada;
    }
  }
`;
