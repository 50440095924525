import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import dayjs, { Dayjs } from 'dayjs';

import notify from 'notify';
import { styled } from 'styles';
import Button from 'components/Button';
import Input from 'components/Input';
import { CustomInputDate } from 'components/InputDate';
import Dropdown from 'components/Dropdown';
import { isWeekendOrHoliday, getNextBusinessDay } from './holidays';

type BeforeAfter = 'before' | 'after';

type FormValues = {
  numOfDays: string;
  startDate: Dayjs;
  beforeOrAfter: BeforeAfter;
};

const beforeOptions = [
  { text: 'Before', value: 'before' },
  { text: 'After', value: 'after' }
];

const getDedlineDate = ({ numOfDays, startDate, beforeOrAfter }) => {
  const method = beforeOrAfter === 'before' ? 'subtract' : 'add';
  let date = dayjs(startDate);

  let i = 0;
  do {
    date = date[method](1, 'day');
    i++;
  } while (i < numOfDays);

  return date;
};

const DeadlineCalculator = () => {
  const [result, setResult] = useState<Dayjs>();
  const [nextBusinessDay, setNextBusinessDay] = useState<{ date: Dayjs; type: BeforeAfter }>();
  const { control, errors, handleSubmit, formState, register } = useForm({
    mode: 'onChange'
  });

  const submit = async (values: FormValues) => {
    setResult(undefined);
    setNextBusinessDay(undefined);
    try {
      const numOfDays = Number(values.numOfDays);
      const result = getDedlineDate({ ...values, numOfDays });
      setResult(result);
      if (isWeekendOrHoliday(result)) {
        const nextBusinessDay = getNextBusinessDay(result, values.beforeOrAfter === 'before');
        setNextBusinessDay({ date: nextBusinessDay, type: values.beforeOrAfter });
      }
    } catch (err) {
      notify(err.message);
    }
  };

  return (
    <StyledDeadlineCalculator>
      <form onSubmit={handleSubmit(submit)} className="tools-popup-form">
        <Input
          ref={register({ required: 'Required' })}
          name="numOfDays"
          error={errors.numOfDays?.message}
          type="number"
          min={1}
          max={9999}
          label="Number of days"
          className="tools-popup-form__days tools-popup-form__item"
          placeholder="Enter"
          data-cy="input_numOfDays"
        />
        <Controller
          control={control}
          rules={{
            required: 'Required',
            validate: value => dayjs(value).isValid() || `Invalid date`
          }}
          name="startDate"
          render={controllerProps => (
            <CustomInputDate
              inputProps={{
                label: 'Start date',
                placeholder: 'Select',
                error: errors.startDate?.message,
                'data-cy': 'input_startDate'
              }}
              className="tools-popup-form__datepicker tools-popup-form__item"
              {...controllerProps}
            />
          )}
        />
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="beforeOrAfter"
          render={controllerProps => (
            <Dropdown
              options={beforeOptions}
              label="Before or after"
              placeholder="Select"
              error={errors.beforeOrAfter?.message}
              className="tools-popup-form__before-after tools-popup-form__item"
              data-cy="dropdown_beforeOrAfter"
              {...controllerProps}
            />
          )}
        />
        <Button
          disabled={!formState.isValid || !formState.isDirty}
          type="submit"
          className="tools-popup-form__button"
          large
          data-cy="button_submit">
          Calculate
        </Button>
      </form>
      {result && (
        <div data-cy="deadline_calculator_results" className="tools-popup-form__results">
          <p className="tools-popup-form__results-text">The deadline is:</p>
          <p className="tools-popup-form__results-text">
            <strong>{result.format('dddd, MMMM D, YYYY')}</strong>
          </p>
          {nextBusinessDay && (
            <>
              <p className="tools-popup-form__results-text">
                The {nextBusinessDay.type === 'after' ? 'next' : 'previous'} business day is:{' '}
                <strong>{nextBusinessDay.date.format('dddd, MMMM D, YYYY')}</strong>
              </p>
            </>
          )}
        </div>
      )}
    </StyledDeadlineCalculator>
  );
};

const StyledDeadlineCalculator = styled.div`
  .tools-popup-form__before-after {
    .dropdown-input {
      outline: none;
    }
    margin-bottom: 16px;
  }

  .tools-popup-form__results-text {
    text-align: center;
  }
`;

export default DeadlineCalculator;
