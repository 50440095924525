import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';

import { styled } from 'styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Tabs from 'components/Tabs';

import DeadlineCalculator from './DeadlineCalculator';
import DayOfYear from './DayOfYear';
import useLayout from 'hooks/useLayout';

type TabKey = 'deadline' | 'day_of_year';

type Tab = { key: TabKey; text: string };

const tabs: Tab[] = [
  { key: 'deadline', text: 'Deadline' },
  { key: 'day_of_year', text: 'Day # of Year' }
];

const ToolsWrapper = ({ children, ...props }) => {
  const layout = useLayout();

  return layout === 'mobile' ? <>{children}</> : <Draggable {...props}>{children}</Draggable>;
};

const Tools = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState<TabKey>('deadline');

  const getTabContent = (key: TabKey) => {
    switch (key) {
      case 'deadline':
        return <DeadlineCalculator />;
      case 'day_of_year':
        return <DayOfYear />;
    }
  };

  return ReactDOM.createPortal(
    <ToolsWrapper handle=".tools-popup__head">
      <ToolsContainer data-cy="tools-popup">
        <div className="tools-popup__head">
          <h2 className="tools-popup__title">Calculator</h2>
          <Button
            onClick={onClose}
            className="tools-popup__button-close"
            data-cy="button_close_tools">
            <Icon name="mini-x" size={8} />
          </Button>
        </div>
        <div className="tools-popup__content">
          <Tabs
            activeTab={activeTab}
            onSelect={(_e, value) => setActiveTab(value.key)}
            items={tabs}
          />
          {getTabContent(activeTab)}
        </div>
      </ToolsContainer>
    </ToolsWrapper>,
    document.querySelector('#modal-root')!
  );
};

export default React.memo(Tools);

const ToolsContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 100px;
  box-sizing: border-box;
  width: 360px;
  z-index: 1000;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  background: #ffffff;
  padding: 12px;
  border-radius: ${props => props.theme.misc.borderRadius};

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .tools-popup__head {
    margin-bottom: 16px;

    .tools-popup__title {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .tools-popup__button-close {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 24px;
      height: 24px;
      float: right;

      &:hover {
        fill: ${props => props.theme.colors.red};
      }
    }
  }

  .tabs {
    flex-wrap: nowrap;
    justify-content: space-between;

    .tab-item {
      width: 100%;
      margin: 0;
      font-weight: 600;
    }
  }
  .tools-popup-form {
    padding: 16px 0 0;
  }
  .tools-popup-form__item {
    margin-bottom: 16px;
    position: relative;
    width: 100%;
  }
  .tools-popup-form__item {
    .rdtPicker {
      max-width: 360px;
    }
  }

  .tools-popup-form__button {
    &.large {
      width: 100%;
    }
  }
  .tools-popup-form__results {
    background: ${props => props.theme.colors.seashell};
    border-radius: ${props => props.theme.misc.borderRadius};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    padding: 12px 24px;
    margin-top: 16px;
    box-sizing: border-box;
  }
  .tools-popup-form__results-text {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
`;
